import styled, { css } from "styled-components";

const Input = styled.input `
  width: 100%;
  font-weight: normal;
  border-radius: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 15px 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 16px;
  border: 0;
  border-bottom: 2px solid #d9d9d9;
  background-color: #f3f3f3;
  outline: none;
  font-family: Arial;
  transition: all .3s;
  color: #222222;

  &:focus {
    border-bottom-color: #005aa0;
  }

  ${props => props.readOnly && css`
    background-color: transparent;
    padding: 0;
    border-bottom: 0;
    margin: 0;
    background-image: none;
    cursor: default;

    &:disabled {
      color: #222222;
    }
  `}

  ${props => props.invalid && css`
      border-bottom-color: ${props.theme.error} !important;
  `}
`;


export default Input;
