import styled from "styled-components";

const ModalParagraph = styled.p `
  margin-top: 0;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export default ModalParagraph;
