import styled from "styled-components";

const Label = styled.label `
  font-family: Arial;
  line-height: normal;
  font-size: 16px;
  margin: 10px;
  display: inline-block;

  @media(max-width: 700px) {
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

export default Label;
