import styled from "styled-components";

const Seperator = styled.span `
  display: inline-block;
  margin: 0 15px;
  font-size: inherit;
  &:after {
    content: "|";
    display: inline;
  }
`;

export default Seperator;
