import 'whatwg-fetch';
import addFilterPropsOnOrder from '../../model/addFilterPropsToOrder'

export const getOrders = (statuses, text, page, activeSort) => {
    if (!text) text = " " ;
    const statusesQuery = "statuses=" + statuses.join(",");
    let textFilters = "";
    if (typeof text !== 'undefined') {
        textFilters = "&text=" + text || "";
    }
   const pageSizes = `&pageSize=${50}`;
   const sortOrder = `&sortOrder=${activeSort[1]}`;
   let sortValue = "";
   switch (activeSort[0]) {
    case "create_date_utc": sortValue = "CreatedAtUtc";
    break;
    case "customer_name": sortValue= "CustomerOrganizationName"
    break;
   }
   
   const sortField = `&sortField=${sortValue}`;
   const startIndex = `&startIndex=${page}`;
   
    return fetchAuthorizedHandler("/v1/orders?" +
    statusesQuery + 
    textFilters +
    pageSizes +
    sortOrder +
    sortField +
    startIndex
       ).then(res => {
        res.orders = res.orders.map(o => addFilterPropsOnOrder(o));
        return res;
    });
};

export const getOrder = (id) => {
    return fetchAuthorizedHandler("/v1/orders/" + id)
        .then(res => {
            addFilterPropsOnOrder(res);
            return res;
        });
}

export const getInvoiceOrder = (id) => {
    return fetchAuthorizedHandler("/v1/invoiceOrders/" + id)
        .then(res => {
            addFilterPropsOnOrder(res);
            return res;
        });
}

export const getPaymentMethods = () => {
    return fetchAuthorizedHandler(`/v1/reports/paymentreport/paymentMethods`);
}

export const getInvoiceVatBasisList = ()=>{
    return fetchAuthorizedHandler(`/v1/reports/paymentreport/invoice/vatbasislist`);
}

export const getOrdersAuditLog = (orderId) => {
    return fetchAuthorizedHandler(`/v1/orders/${orderId}/auditLog`);
}

export const getSecrets = () => {
    return fetchAuthorizedHandler("/v1/client/secrets");
}

export const getIsTestEnvironment = () => {
    return fetchAuthorizedHandler(`/v1/settings/isTest`);
}

export const createSecret = command => {
    return postData("/v1/client/secrets/create", command);
}

export const deleteSecret = command => {
    return postData("/v1/client/secrets/delete", command);
}

export const getAccesToken = (clientSecret) => {
    return fetchAuthorizedHandler(`/v1/client/token?client_secret=${clientSecret}`);
}

export const getClientId = () => {
    return fetchAuthorizedHandler("/v1/client/id");
}

export const getUsers = () => {
    return fetchAuthorizedHandler("/v1/users", (req, res) => {
        console.log('profile', req.user.profile);
        res.send(req.user.profile._json);
    });
}

export const createUser = (name, email) => {
    return postData("/v1/users", {
        name,
        email
    });
}

export const updateUser = (id, name, email) => {
    return putData(`/v1/users/${id}`, {
        name,
        email
    });
}

export const getTerms = () => {
    return fetchAuthorizedHandler("/v1/terms");
}

export const getDisclaimer = () => {
    return fetchAuthorizedHandler("/v1/disclaimers");
}

export const updateTermsUrl = (body) => {
    return putData(`/v1/terms`, body);
}

export const removeUser = (id) => {
    return deleteData(`/v1/users/${id}`);
}

export const getMe = () => {
    return fetchAuthorizedHandler("/v1/me");
}

export const updatePassword = (data) => {
    return postData("/v1/me/changepassword", data, "Ändra lösenord.");
}

export const shipCartItems = (orderId, items, comments) => {
    return postData(
        `/v1/orders/${orderId}/shipments`,
        {
            "order_items": items
                .filter(f => f.quantity > 0)
                .map(m => {
                    return {id: m.id, quantity: m.quantity}
                }),
            "comments": comments
        });
}

export const shipInvoiceOrder = (orderId, comments) => {
    return postData(
        `/v1/invoiceOrders/${orderId}/shipments`,
        {
            "comments": comments
        });

}

export const creditInvoiceShipment = (orderId, shipmentId) => {
    return postData(
        `/v1/invoiceOrders/${orderId}/credit/${shipmentId}`,
        {});
}

export const cancelCartItems = (orderId, items, comments) => {
    return postData(
        `/v1/orders/${orderId}/cancellations`,
        {
            "order_items": items
                .filter(f => f.quantity > 0)
                .map(m => {
                    return {id: m.id, quantity: m.quantity}
                }),
            "comments": comments
        });
}

export const cancelInvoiceOrder = (orderId, comments) => {
    return postData(
        `/v1/invoiceOrders/${orderId}/cancel`,
        {
            "comments": comments
        });
}

export const validateUpdateCartItems = (orderId, addedItems, canceledItems, updatedAtUtc, comments, editedShippingAmount) => {
    return postData(
        `/v1/orders/${orderId}/validateupdatecartitems`,
        {
            "added_cart_items": addedItems.filter(f => f.quantity > 0),
            "deleted_cart_items": canceledItems.filter(f => f.quantity > 0),
            "updated_at_utc": updatedAtUtc,
            "edited_shipping_amount": editedShippingAmount,
            "comments": comments
        });
}

export const updateCartItems = (orderId, addedItems, canceledItems, updatedAtUtc, comments, editedShippingAmount, originalShippingAmount) => {
    return postData(
        `/v1/orders/${orderId}/updatecartitems`,
        {
            "added_cart_items": addedItems.filter(f => f.quantity > 0),
            "deleted_cart_items": canceledItems.filter(f => f.quantity > 0),
            "updated_at_utc": updatedAtUtc,
            "comments": comments,
            "edited_shipping_amount": editedShippingAmount,
            "original_shipping_amount": originalShippingAmount

        });
}

export const resendSingEmailLink = (orderId, recipientName, recipientEmail) => {
    return postData(
        `/v1/resendLink/${orderId}`,
        {
            "Name": recipientName,
            "Email": recipientEmail,
        });
}

const fetchAuthorizedHandler = (url, description) => {

    return new Promise((resolve, reject) => {
        fetch(url, {
            credentials: 'include',
            redirect: 'error',
        })
            .then(errorHandler)
            .then(response => {
                resolve(response.json());
            })
            .catch(err => {
                reject(err);
            })
    });
}


const putData = (url, data, description) => {
    return putOrPost(url, data, description, "PUT");
}

const deleteData = (url, data, description) => {
    return putOrPost(url, data, description, "DELETE");
}

const postData = (url, data, description) => {
    return putOrPost(url, data, description, "POST");
}

const putOrPost = (url, data, description, method) => {
    return new Promise((resolve, reject) => {

        fetch(url, {
            method: method,
            credentials: 'include',
            redirect: 'error',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then(errorHandler)
            .then(response => {
                resolve(response.json());
            })
            .catch(err => {
                reject(err);
            });
    })
}

const errorHandler = (response) => {
    if (response.ok)
        return response;

    if (response.status >= 400 & response.status < 500) {
        return response.json()
            .then(json => {
                console.warn('Bad request : body', json);
                var error = Error(json.description);
                error.developer_message = json.developer_message;
                throw error;
            })
            .catch(err => {
                console.warn('Bad request no json body:', err.message);
                throw err;
            });
    }

    return response.json()
        .then(json => {
            if (json.developer_message) {
                console.error('Internal server error:', json.developer_message);
                throw Error(json.developer_message);
            } else {
                console.error('Internal server error:', json);
            }
            throw Error(response.statusText);
        });

}