import { getMe } from './api';
import cache from './authCache';

export class AuthService {
    static instance = undefined;
    timer = null;
    constructor() {
        if (!!AuthService.instance) {
            return AuthService.instance;
        }

        AuthService.instance = this;
        this.timer = setInterval(this.checkAuth, 31 * 60 * 1000);
        return this;
    }

    profile() {
        if (cache.hasValue()) {
            return Promise.resolve(cache.get());
        }
        return getMe().then(me => {
            cache.set(me);
            return me;
        }).catch((err) => {
            clearInterval(this.timer);
                // var parts = window.location.href.split('/').filter(Boolean);
                // if (parts.pop() != 'logout') {
                //     window.location.href = "/app/login";    
                // }
        });

    }

    checkAuth() {
        getMe()
            .then(me => cache.set(me))
            .catch(x => {
                clearInterval(this.timer);
                var parts = window.location.href.split('/').filter(Boolean);
                if (parts.pop() !== 'logout') {
                    window.location.href = '/logout'
                }
            });
    }
}