import React from 'react';
import t from "../../translation";
import {Row, Box, Item} from "./OrderTop";
import {format} from "../../utils";
import ValidToInfo from './ValidToInfo';

const OrderDetailsTopInfo = (props) => {
    return (
        <React.Fragment>
            <div>
                <Row>
                    <Item top="15px" width="100%">
                        {t(`ORDER_DETAILS_ORDER_NUMBER`)} <br/>
                        <strong data-hj-whitelist id={"order-details-order-number"}>
                            {props.order.order_number}
                        </strong>
                    </Item>
                   
                        {
                            <Item top="15px" width="100%">
                                <ValidToInfo order={props.order}/>
                            </Item>

                        }
                </Row>
                <Row>
                <Item top="15px" width="100%">
                    {t(`ORDER_DETAILS_CONTRACT_NUMBER`)} <br/>
                        <strong data-hj-whitelist id={"order-details-contract-number"}>
                            {props.order.agreement_external_reference}
                        </strong>                    
                    </Item>
                {
                    props.order.partner_domain ?
                            <Item top="15px" width="100%">
                                {t(`ORDER_DETAILS_PARTNER_DOMAIN`)} <br/>
                                <strong data-hj-whitelist id={"order-details-partner-domain"}>
                                    {props.order.partner_domain}
                                </strong>
                            </Item>
                        : null
                }
               </Row>
            </div>
            <Box>
                <Row>
                    <Item bottom="20px">
                        {t(`ORDER_DETAILS_COMPANY_NAME`)} <br/>
                        <strong data-hj-suppress id={"order-details-company-name"}>
                            {props.order.customer_name}
                        </strong>
                    </Item>
                    <Item bottom="20px">
                        {t(`ORDER_DETAILS_ORGANIZATION_NUMBER`)} <br/>
                        <strong data-hj-suppress id={"order-details-organization-number"}>
                            {props.order.customer_organization_number}
                        </strong>
                    </Item>
                </Row>
            </Box>
        </React.Fragment>
    )


}
export default OrderDetailsTopInfo;




