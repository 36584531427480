import React from 'react';
import t from "../../translation";
import { Row, Item } from "../OrderDetails/OrderTop";
import Address from "../OrderDetails/Address";
import AddressTitle from "../OrderDetails/AddressTitle";
import "./style.scss";
const InvoiceBillingAddress = (props) => {
    return (
        <Row id="invoice-billing-address-container">
            <Item width="100%" bottom="15px">
                <AddressTitle>{t(`ORDER_DETAILS_BILLING_ADDRESS`)}</AddressTitle>
                <Address data-hj-suppress id={"order-details-billing-address"}>
                    {props.order.billing_address.company_name} <br />
                    {props.order.billing_address.street_address} <br />
                    {`${props.order.billing_address.postal_code} ${props.order.billing_address.city}`}
                </Address>
            </Item>
            <Item width="100%" bottom="15px">
                <AddressTitle>{t(`ORDER_DETAILS_PURCHASER_HEADER`)}</AddressTitle>
                {props.order.purchaser_name && props.order.purchaser_name.length > 0 && <p>{props.order.purchaser_name}</p>}
                <a data-hj-suppress id={"order-details-purchaser-email"} href={"mailto:" + props.order.purchaser_email}>
                    {props.order.purchaser_email}
                </a>
                <h4>Fakturareferens</h4>
                {props.order.billing_details.billing_reference} <br />
                <h4>Märkning</h4>
                {props.order.billing_details.billing_tag} <br />
                {
        ( props.order.partner_reference !==null && props.order.partner_reference.length  > 0) &&
        <div>
        <h4>Partnerns referens:</h4> {props.order.partner_reference}
        </div>
        
    }
            </Item>
        </Row>
    )
}
export default InvoiceBillingAddress;




