import React from 'react';
import format from '../../utils/format';
import './auditLog.css';
export const AuditLog = (props) => {
   
    const _log = (props.log && props.log!=null ) ? props.log : [];
    if (_log.length === 0) {
        return (
            <div></div>
        )
    }

    return (
        <div className="audit-log-container">
            <table className="audit-log-table">
                <thead>
                    <tr>
                        <th>Händelse</th>
                        <th nowrap="true">Datum</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        _log.map((e, i) => {
                            return (
                                <tr key={'auditRow' + i}>
                                    <td>{e.text}</td>
                                    <td nowrap="true" title={format.dateWithTime(e.created_at_utc)}>{format.dateWithTime(e.created_at_utc)}</td>
                                </tr>
                            );
                        })
                    }

                </tbody>
            </table>
        </div>
    )
};



