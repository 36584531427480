import React from 'react';
import {Td} from "../OrderTable";

const EditShipping = (props) => {
    return (
        <tr className={`edit-shipping_${(props.isEditShippingEnabled === false)? 'disabled':'' }`}>
        <Td  colSpan="4">Frakt</Td>
        <Td compress noWrap rightAlign>
            <input className="shippingInput" onChange={(e)=> props.onChangeShipping(e.target.value)}
             type="text" id="shippingCostEditInput" 
             name="shippingCostEditInput" 
             disabled={props.isEditShippingEnabled===false}
             title={props.isEditShippingEnabled===false ? 'Går ej att ändra frakt efter delleverans':''}
             value={props.editedShippingAmount}/>  kr</Td>
    </tr>
    )
}
export default EditShipping;






