import React from 'react';
import styled from "styled-components";

class Counter extends React.Component {
    constructor(props) {
        super(props);

        props.setter(props.rangeTo);
    }

    render() {
        return (
            <CounterBox defaultValue={ this.props.rangeTo } onChange={ ev => this.props.setter(ev.target.value || 0) }>
                { this.renderList(this.props.rangeFrom, this.props.rangeTo) }
            </CounterBox>);
    }

    renderList(from, to) {
        var ret = [];
        for (let i=from;i<=to;i++)
            ret.push((<option key={ i } value={ i }>{ i }</option>));
        return ret;
    }    
}

const CounterBox = styled.select `
  color: #222;
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-style: normal;
  padding: 2px;
  width: 60px;
`;

export default Counter;