import React from 'react';
import styled from "styled-components";
import theme from "../../theme";
import checkboxCheck from "../../assets/checkbox_check.svg";

class Checkbox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: !!props.defaultValue
        };
    }

    render() {
        return (
            <CheckboxBox 
                onClick={ ev => {
                    this.setState(state => ({ checked: !state.checked }), () => this.props.onCheck(this.state.checked)); 
                    ev.stopPropagation(); } }>
                <CheckboxBoxSelected visible={ this.state.checked } />
            </CheckboxBox>);
    }
}

const CheckboxBox = styled.div `
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #F3F3F3;
    border: 2px solid #D9D9D9;
    box-sizing: border-box;

    &[data-checked=]
`;

const CheckboxBoxSelected = styled.div `
    border: 0;
    width: 18px;
    height: 18px;
    margin: -2px;
    background-image: url(${checkboxCheck});    
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${theme.readyToShip};

    display: ${props => props.visible ?  'block' : 'none' }
`;

export default Checkbox;