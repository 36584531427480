import styled from "styled-components";

import { Input } from "../styled";

const CreateUserInputWrapper = styled.div `
  margin-right: 20px;
  width: 100%;
`;

export default CreateUserInputWrapper;
