
import React from "react";
import styled from "styled-components";
import t from "../../translation";

import trash from '../../assets/trash.svg'

const Icon = styled.div `
border: 0; 
width: 1em;
height: 1em;
display: inline-block;
vertical-align: top;
mask-size: contain;
mask-position: center;
mask-repeat: no-repeat;
`

const TrashIcon = styled(Icon).attrs({
    title: t("Ta bort")
}) `
    background-color: blue;
    mask-image: url(${trash});  
`;

export default () => {
    return (<TrashIcon />);
};