import styled, { css } from "styled-components";

const LoadingUsers = styled.div `
  font-family: 'Intro Cond',Arial,"Helvetica Neue",sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  color: #00427a;
  display: block;
  text-align: center;
`;

export default LoadingUsers;
