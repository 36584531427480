import styled from "styled-components";

const FilterStatusHeader = styled.span `
  font-family: 'Intro Cond Bold', Arial, "Helvetica Neue", sans-serif;
  line-height: normal;
  font-size: 12px;
  text-transform: uppercase;
  color: #747578;
  margin-right: 10px;
  font-weight: normal;
`;

export default FilterStatusHeader;
