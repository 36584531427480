import React from "react"
import './style.scss';

export const PageButtons = (props) => {
        const {loadPage, totalCount, activePage, isLoading} = props;
        const pageSize = 50;
        const countPage = Math.fround(totalCount / pageSize)
        let pageAmount = countPage > Math.floor(countPage) ? Math.ceil(countPage) : Math.floor(countPage);
        if (pageAmount === 0) pageAmount = activePage;
        let buttonAmount = pageAmount >= 5 ? 5 : pageAmount;
        let grayOut = activePage === pageAmount;
        let pageBoxList = Array.from({length: Math.min(buttonAmount, pageAmount)}, (_, i) => {
            if (pageAmount > buttonAmount && activePage > pageAmount - 2) {
                return (pageAmount - 5) + i + 1;
            }
            if (activePage >= 5) {
                return i + 1 + (activePage - 4);
            }
            else return i + 1;
        });

    return (
        <React.Fragment>
            <button className="pg-but arrow-button" aria-label="first-page-arrow-button" onClick={() =>loadPage(1)}>
                <i className="fa-solid fa-angles-left"/>
            </button>
            <button className="pg-but arrow-button" disabled={isLoading || activePage == 1} type='button' aria-label="left-arrow-button" onClick={() => loadPage(activePage - 1)}>
                <i className="fa-solid fa-angle-left"/>
            </button>
            {
                pageBoxList.map((page, i) => {
                    if (page > pageAmount) {
                        return null;
                    }
                    else {
                        return (
                            <button className="pg-but rendered-button" key={i} disabled={activePage == page || page > pageAmount} type="button" onClick={() => {loadPage(page)}}>{page}</button>
                            )
                    }
            })}
            <button className="pg-but arrow-button" disabled={isLoading || grayOut} type="button" aria-label="left-arrow-button" onClick={() => loadPage(activePage + 1)}>
                <i className="fa-solid fa-angle-right"/>
            </button>  
            <button className="pg-but arrow-button" aria-label="last-page-arrow-button" onClick={() => loadPage(pageAmount)}>
                    <i className="fa-solid fa-angles-right"/>       
            </button>
        </React.Fragment>
    )

}


export default PageButtons;