import React from 'react';

import '../../extensions/DateEx';
import t from '../../translation';

import { validation } from '../../utils';

import { createSecret } from '../../services/api';
import { Input,
         InputLabel,
         ErrorMessage,
         InformationMessage,
         SectionWrapper,
         SectionHeader,
         SectionIntro,
        InputWrapper } from "../styled";

import { SecondaryButton } from '../styled/Buttons';


import CreateSecretForm from './CreateSecretForm';
import CreateSecretButton from './CreateSecretButton';
import CopyField from './CopyField';

export default class CreateSecretControl extends React.Component {
    constructor(props) {
        super(props);

        const refDate = new Date();

        this.state = {
            command: {
                description: '',
            },
            expire_date: refDate.addYears(2).toISODate(),
            showSecret: false,
            secret: null,
            clientId: null,
            suppressErrorMessages: true,
            formErrorMessage: null,
            infoMessage: null
        };

        this.onCloseSecretView = this.onCloseSecretView.bind(this);
        this.isValidName = this.isValidName.bind(this);
    }

    setDescription(val) {
        this.setState(prevState => ({
            ...prevState,
            suppressErrorMessages: false,
            command: {
                ...prevState.command,
                description: val,
            },
        }));
    }

    onCopySecret() {
        this.setState({
            infoMessage: t('COPIED'),
        });
    }

    onCreate(e) {
        e.preventDefault();
        createSecret(this.state.command)
            .then(res => {
                this.setState({
                    suppressErrorMessages: true,
                    showSecret: true,
                    secret: res.secret,
                    clientId: res.client_id,
                });
            }).catch(err=>{
                this.setState({
                    formErrorMessage:err.developer_message
                });
            });
    }

    refreshParent() {
        if(this.props.refresh) {
            this.props.refresh();
        }
    }

    isValidName() {
        if (!validation.isString(this.state.command.description, 5) || 
            (this.props.data.map(s => {
                return s.description
            }).indexOf(this.state.command.description) > -1))
        {
            return false;
        }
        return true;
    }

    onCloseSecretView() {
        this.setState(prev => ({
            ...prev,
            showSecret: false,
            infoMessage: null,
            command: {
                description: '',
            }
        }));
        this.refreshParent();
    }

    render() {
        const creatingSecret = false;
        const suppressErrorMessages = this.state.suppressErrorMessages;
        const haveErrorMessage = (this.state.formErrorMessage && this.state.formErrorMessage !== null) ? true : false;

        return (
            this.state.showSecret ?
            <SectionWrapper>
                <SectionHeader>{ t("NEW_CLIENT_SECRET")}</SectionHeader>
                <SectionIntro>{ t("NEW_CLIENT_SECRET_EXPLAIN")}</SectionIntro>
                <InputWrapper>
                    <CopyField text={this.state.clientId} onCopied={this.onCopySecret.bind(this)} id={"Client-id"}  label={t("CLIENT_SECRET_CLIENT_ID")}/>
                </InputWrapper>
                <InputWrapper>
                    <CopyField text={this.state.secret} onCopied={this.onCopySecret.bind(this)} id={"Api-key"} label={t("SECRET")}/>
                </InputWrapper>
                <SecondaryButton id="close-copy-secret-view" onClick={() => this.onCloseSecretView()}>{ t("CLOSE") }</SecondaryButton>
                <InformationMessage visible>{this.state.infoMessage}</InformationMessage>
            </SectionWrapper>
            :
            <SectionWrapper>
                <SectionHeader>{ t("NEW_CLIENT_SECRET") }</SectionHeader>
                <SectionIntro>{ t("NEW_CLIENT_SECRET_DESCRIPTION") }</SectionIntro>
                <SectionIntro>{ t("NEW_CLIENT_SECRET_INFORMATION_PART_1") +  this.state.expire_date + t("NEW_CLIENT_SECRET_INFORMATION_PART_2") }</SectionIntro>
                <CreateSecretForm>
                    <InputWrapper>
                        <InputLabel htmlFor={"CREATE-SECRET-DESCRIPTION"}>
                            {t("NAME")}
                        </InputLabel>
                        <Input
                            type="text" 
                            id={"CREATE-SECRET-DESCRIPTION"} 
                            value={this.state.command.description} 
                            onChange={e => this.setDescription(e.target.value)}
                            />
                        <ErrorMessage visible={ !suppressErrorMessages && !this.isValidName() }>
                        { t("VALIDATION_ERROR_SECRET_NAME") }
                        </ErrorMessage>
                    </InputWrapper>
                    <CreateSecretButton onClick={e => this.onCreate(e)} id="add-secret-submit" large secondary disabled={ creatingSecret || !this.isValidName() }>
                        { creatingSecret ? t("NEW_USER_CREATING_BUTTON") : t("NEW_SECRET_CREATE_BUTTON") }
                    </CreateSecretButton>
                </CreateSecretForm>
                <ErrorMessage block visible={ haveErrorMessage }>{ this.state.formErrorMessage }</ErrorMessage>
            </SectionWrapper>
        );
    }
}