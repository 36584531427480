import styled, { css } from "styled-components";

const Box = styled.div `
  margin-top: 20px;
  padding-top: 20px;
  background: #f3f3f3;
`;

const Bordered = styled.div `
  margin-top: 20px;
  border: 1px solid #f3f3f3;
`;

const Flow = styled.ul `
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: flex-start;
`;

const Row = styled(Flow) `
  flex-direction: row;

  @media(max-width: 500px){
    flex-direction: column;
  }
`;

const Column = styled(Flow) `
  flex-direction: column;
`;

const Item = styled.li `
  width: 50%;
  margin-left: 20px;
  margin-right: 20px;
  
  ${props => props.top && css`
    margin-top: ${props.top};
  `};

  ${props => props.width && css`
    width: ${props.width};
  `};

  ${props => props.bottom && css`
    margin-bottom: ${props.bottom};
  `};

  ${props => props.rightAlign && css`
    margin-right: 0px;
  `};
`;

const Hr = styled.hr `
  margin: 20px;
  height: 1px;
  background: #f3f3f3;
  border: 0;
`;

const Fixed = styled.span `
  display: inline-block;
  width: 100px;
`;

const Summary = styled.h3 `
  font-family: 'Intro Cond Bold',Arial,"Helvetica Neue",sans-serif;
  margin-top: 15px;
  color: #003d60;
  font-weight: normal;
  padding: 0 20px;
`;

export { Row, Column, Box, Bordered, Item, Hr, Fixed, Summary };
