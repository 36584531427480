import styled, { css } from "styled-components";

const Tr = styled.tr `
  background-color: #ffffff;
  height: 50px;

  &:hover {
    background-color: #eeeeee;
    cursor: pointer;
  }

  ${props => props.noHover && css`
    &:hover {
      background-color: #ffffff;
      cursor: default;
    }
  `}

`;

export default Tr;
