import React from 'react';
import { Tr, Td, TFoot, TrTotalCost, TrTotalCostIncVat } from "../OrderDetails/OrderTable";
import t from "../../translation";
import { newAndShinyCurrencyFormat } from '../../utils/format';

const Sum = (props) => {
    return (
        <React.Fragment> 
        <TFoot>
            <TrTotalCost>
                <Td bold>{ t(`ORDER_DETAILS_PRICE_TOTAL`) }</Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td id="change-order-details-total-cost-ex-vat" rightAlign noWrap bold>{ newAndShinyCurrencyFormat(props.totalPriceExclVat, props.currency,2) }</Td>
            </TrTotalCost>
            <Tr>
                <Td>{ t(`ORDER_DETAILS_VAT`) }</Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td id="change-order-details-total-vat" rightAlign noWrap>{ newAndShinyCurrencyFormat(props.totalVatAmount, props.currency, 2) }</Td>
            </Tr>
            <TrTotalCostIncVat>
                <Td>{ t(`ORDER_DETAILS_PRICE_TOTAL_INC_VAT`) }</Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td id="change-order-details-total-cost-inc-vat" rightAlign noWrap>{ newAndShinyCurrencyFormat(props.totalVatAmount + props.totalPriceExclVat, props.currency, 2) }</Td>
            </TrTotalCostIncVat>
        </TFoot>      
        </React.Fragment>
    )
}
export default Sum;






