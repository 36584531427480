import moment from 'moment';

const currencyFormat = currency => {
  switch(currency.toUpperCase()) {
    case "EUR":
      return { prefix: "€", suffix: "" };
    case "GBP":
      return { prefix: "£", suffix: "" };
    case "SEK":
      return { prefix: "", suffix: " kr" };
    case "USD":
      return { prefix: "$", suffix: "" };
    default:
      return { prefix: "", suffix: "" };
  }
};

const round = number => {
  var factor = Math.pow(10, 0);
  return Math.round(number * factor) / factor;
}

const currencyAux = (amountTransformer) => {
  return (amount, currency) => {
    const parsedAmount = amountTransformer(amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    const c = currencyFormat(currency);
    return `${c.prefix}${parsedAmount}${c.suffix}`;
  }
};

export const newAndShinyCurrencyFormat = (value, currency, fractionDigits) =>{
  let curr = currency || "SEK";
  let _fractionDigits = fractionDigits || 2;
  return new Intl.NumberFormat('sv-SE', { style: 'currency', currency: curr, minimumFractionDigits: _fractionDigits }).format(value);
}

export default {
  currency: currencyAux(round),
  currencyToFixed: (fractionDigits) => currencyAux(val => typeof val == 'number' ? val.toFixed(fractionDigits) : val),

  date: (value) => {
    try {
      return moment.utc(value).local().format('YYYY-MM-DD');
    } catch(e) {
      return value;
    }
  },  
  dateWithTime: (value) => {
    try {
      return moment.utc(value).local().format('YYYY-MM-DD HH:mm:ss');
    } catch(e) {
      return value;
    }
  },
  dateWithTimeWithoutSeconds: (value) => {
    try {
      return moment.utc(value).local().format('YYYY-MM-DD HH:mm');
    } catch(e) {
      return value;
    }
  },  
  diffInDaysFromNow: (value) => {
    try {
        return moment().diff(moment.utc(value), 'days');
    } catch (e) {
        return value;
    }
}

}
