import React, { Component } from 'react';

import t from '../../translation';

import {
    Table,
    THead,
    THeadTr,
    Th,
    TBody,
} from '../styled';

import SecretRowItem from './SecretRowItem';

export default class SecretListControl extends Component {
    render() {
        return (
            <Table>
                <THead>
                    <THeadTr>
                        <Th>{ t("EXPIRES") }</Th>
                        <Th>{ t("NAME")}</Th>
                        <Th>&nbsp;</Th>
                    </THeadTr>
                </THead>
                <TBody>
                    {this.props.data.map((s, idx) => {
                        return <SecretRowItem key={'c' + idx} data={s} refresh={this.props.refresh}/>
                    })}
                </TBody>
            </Table>
        );
    }
}