import styled from "styled-components";

const Address = styled.address `
  color: #222;
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-style: normal;
`;

export default Address;
