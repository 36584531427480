import React, { useState } from 'react';
import t from "../../translation";
import { Summary } from "../OrderDetails/OrderTop";
import { ModalWindow, ModalOverlay } from "../OrderDetails/OrderModal";
import { PrimaryButton } from "../styled/Buttons";
import Spinner from "../LfSpinner";


import "./style.scss";
const InvoiceShippingModal = (props) => {
    const [comments, setComments] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [busy, setBusy] = useState(false);
    const onShip = (comments)=>{
        setBusy(true);
        props.onShip(comments).then(()=>{
            setBusy(false);
           //do nothing
        }).then(r=>{
            setBusy(false);
            setErrorMessage(null);
        }).catch((err)=>{
            setBusy(false);
            setErrorMessage(err);
            console.log(err + ", försök igen senare."); //really bad
        })

    }
    return (
        <ModalOverlay onClick={ (ev) => { ev.stopPropagation(); props.onClose(); } }>

     <ModalWindow className="invoice-ship-cancel-modal" id="invoice-shipping-modal" onClick={(ev) => { ev.stopPropagation(); }}>

            <Summary>{t(`ORDER_DETAILS_SHIP_ITEMS_HEADER`)}</Summary>
                        <div className="comment-container">
                            <input type="text" style={{ padding: "1em", margin: "1em 0 1em 0" }} defaultValue={comments} onChange={ e => setComments(e.target.value)} placeholder={ t(`ORDER_DETAILS_COMMENTS_FIELD`) } />
                        </div>

                        <PrimaryButton 
                            disabled={busy}

                            id={"order-details-confirm-modal-button"} 
                            onClick={ () => onShip(comments) } 
                            >
                            {busy ?
                                    <span className="no-wrap">
                                    Bekräftar leverans...&nbsp;<Spinner isButtonSpinner={true} />
                            </span>
                            :
                            t(`ORDER_DETAILS_CONFIRM_SHIPMENT`)
                            }

                        </PrimaryButton>
                        {
                            errorMessage!==null &&
                            <div className="error-message">{errorMessage}</div>
                        }

        </ModalWindow>
        </ModalOverlay>
    )


}
export default InvoiceShippingModal;
