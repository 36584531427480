export default {
  _translationKeys: {
    "pending_signing": ["ORDER_STATUS_PENDING_SIGNING"],
    "pending_approval": ["ORDER_STATUS_PENDING_APPROVAL"],
    "pending": ["ORDER_STATUS_PENDING"],
    "ready_to_ship": ["ORDER_STATUS_READY_TO_SHIP"],
    "shipped": ["ORDER_STATUS_SHIPPED"],
    "completed": ["ORDER_STATUS_COMPLETED"],
    "canceled": ["ORDER_STATUS_CANCELED"],
  },
  pending_signing: "pending_signing",
  pending_approval: "pending_approval",
  pending: "pending",
  readyToShip: "ready_to_ship",
  shipped: "shipped",
  completed: "completed",
  canceled: "canceled",
}
