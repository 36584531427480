import React, {useState} from 'react';
import "./style.scss";
const AccordionWrapper = (props) => {
    const [expanded, setExpanded] = useState(false);
    const toggle = () => {
        let newValue = !expanded;
        setExpanded(newValue);
    }

    return (
        <React.Fragment>
            <div className={"table-accordion " + (expanded ? 'expanded':'') }  onClick={() => toggle()} >
                {props.title}
            </div>
            <div className={'accordion-content ' + (expanded? '':'collapsed')}>
                {props.children}
            </div>
        </React.Fragment>
    )
};
export default AccordionWrapper;
