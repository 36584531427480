import React from 'react';
import {format} from "../../utils";
import {ReactComponent as PartiallyShipped}  from '../../assets/half-filled-circle.svg'
import {ReactComponent as LfuiClock} from '../../assets/lfui-clock.svg';
import orderStatus from "../../enums/orderStatus";
import t from "../../translation";

const OrderStatus = (props) => {
    const needsValidToWarning = (offsetDays) =>{
        return ([orderStatus.shipped,orderStatus.canceled].includes(order.status)===false) 
         && (format.diffInDaysFromNow(order.valid_to) * -1) <= offsetDays;
    }
    const getValidToWarningText = ()=>{
        return `Order kommer att avslutas inom ${format.diffInDaysFromNow(order.valid_to) * -1} dagar`;
    }
    const order = props.order;
    return(
        <div className="icon-container">
        {
          (order.shipments_count > 0 && order.status === orderStatus.readyToShip) ?
            <div className={`icon old-partially-shipped`}>
                <PartiallyShipped title={t("ORDER_DETAILS_PARTIALLY_SHIPPED")}  className="svg-icon partially-shipped" fill="orange"/>
            </div>
            : null
        }
        { 
            
            needsValidToWarning(15) ?
            <div  className={`icon valid-to-alert-high`}>
                <LfuiClock title={getValidToWarningText()} className="svg-icon" fill="#E30613"/>

            </div>
                :
                needsValidToWarning(30)  ?
                <div  className={`icon valid-to-alert-low`}>
                    <LfuiClock title={getValidToWarningText()} className="svg-icon" fill="orange"/>

                </div>
                :null
        }

    </div>
    )
    
   
}
export default OrderStatus;




