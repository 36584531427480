import styled from "styled-components";

import { Th } from "../styled";

const StatusTh = styled(Th) `
  padding: 0;
  width: 6px;
`;

export default StatusTh;
