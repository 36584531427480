import React, { Component } from "react";
import { ThemeProvider } from "styled-components";
import colors from "./theme/colors.js";
import Header from "./components/containers/Header.jsx";
import Main from "./components/containers/Main.jsx";
import { AuthService } from './services/authService';
import { HeaderWrapper } from "./components/styled/index.js";
import Spinner from "./components/LfSpinner";
import Logout from "./components/containers/Logout";
import wkLogo from "./assets/wk.png";
import "./App.css"
import { DisclaimerInfo } from "./components/Disclaimer/index.js";
import "@fortawesome/fontawesome-free/css/all.css";

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testMode: false,
            isLoggedIn: false,
            isLoading: true,
        };

        this.authService = new AuthService();
        this.authService.profile()
            .then(x => {
                if (x && x.name) {
                    this.setState({
                        isLoggedIn: true,             
                        isLoading: false
                    });
                } else {
                    var parts = window.location.href.split('/').filter(Boolean);
                    if (parts.pop() !== 'logout') {
                        window.location.href = '/app/login'
                    } else {
                        this.setState({
                            isLoggedIn: false,             
                            isLoading: false
                        });
                    }
                }
            });         
    }

    render() {
        return (
            <div>
                {this.state.isLoading ? (
                <div className="busy-wrapper">
                    <Spinner busy={this.state.isLoading}/>
                </div>
                ) : (
                    <div>
                    {this.state.isLoggedIn ? (
                            <ThemeProvider theme={colors}>
                                <div>
                                    <DisclaimerInfo/>
                                    <Header />
                                    <Main />
                                </div>
                            </ThemeProvider>
                        ) : (
                        <div>
                            <HeaderWrapper>
                                <img src={wkLogo} alt="WasaKredit logo"></img>
                            </HeaderWrapper>
                            <Logout/>
                        </div>
                    )}
                    </div>
                )
            }
            </div>
        
        );
    }
}

