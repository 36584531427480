import React, {useState} from 'react';
import { format } from "../../utils";
import { SecondaryButton } from "../styled/Buttons";
import "../OrderDetails/Shipments/shipments.scss";
const ShipmentHeader = (props) => {
    const [busy, setBusy] = useState(false);
    const preCreditOrder = (event,id)=>{
        event.preventDefault();
        event.stopPropagation();
        if(busy){
            return;
        }
        setBusy(true);
        props.onCreditOrder(id);
    }

    const hasDebitedShipmentsWithPayments = (shipment) => {
        return shipment.debited_shipment && shipment.debited_shipment.payments.length !== 0;
    }
    const showCreditingButton = (shipment) => {
        return (shipment.credited_shipment === null && (shipment.debited_shipment && shipment.debited_shipment !==null))
    }
    return (
        <React.Fragment>
            <span className="shipment-date">{format.dateWithTime(props.shipment.shipped_at)} </span>
            {
                showCreditingButton(props.shipment) && 
                <SecondaryButton className="crediting-button" title={hasDebitedShipmentsWithPayments(props.shipment) ? 'Leverans helt eller delvis betald':'' } 
                    disabled={busy || hasDebitedShipmentsWithPayments(props.shipment)}
                onClick={(event) => preCreditOrder(event,props.shipment.id)}> Kreditera </SecondaryButton>}
            {
                (props.shipment.credited_shipment !== null ) &&
            <span className="credited-info" title={format.dateWithTime(props.shipment.credited_shipment.credited_at)}>Krediterad {format.dateWithTime(props.shipment.credited_shipment.credited_at)}</span>
            }
        </React.Fragment>
    )
}
export default ShipmentHeader;




