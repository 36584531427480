import React, { Component } from 'react';
import styled from "styled-components";

const ModalContent = styled.div `
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 2.5rem;
    background-color: white;
    border-radius: 0.3125rem;
    box-shadow: 0 0 2.5rem rgba(0,0,0,0.5);
`;

const ModalContainer = styled.div `
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,.25);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    opacity: 1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    cursor:auto;
  `;

export { ModalContent, ModalContainer };

export default class ModalPopup extends Component {
    render() {
        return (
            <ModalContainer>
                <ModalContent>
                    {this.props.children}
                </ModalContent>
            </ModalContainer>
        );
    }
}

