import React from 'react';
import t from "../../translation";
import { Summary } from "../OrderDetails/OrderTop";
import { Table, TBody, TFoot, Thead, Tr, Th,  Td, TdComments } from "../OrderDetails/OrderTable";
import { format } from "../../utils";
import "../OrderDetails/Shipments/shipments.scss";
import ShipmentHeader from './ShipmentHeader';
import AccordionWrapper from '../AccordionWrapper';
const Shipments = (props) => {
    let formatCurrency = props.formatCurrency || format.currency;
    const hasDebitedShipments = (shipment) => {
        return shipment.debited_shipment && shipment.debited_shipment.payments.length !== 0;
    }

    return (
        <div>
            <Summary className="shipments-summary">{t(`ORDER_DETAILS_ORDER_SHIPMENTS_HEADER`)}</Summary>
            {props.shipments.map((shipment, index) =>
            (<div key={shipment.id} >
                <AccordionWrapper title={<ShipmentHeader shipment={shipment} onCreditOrder={props.onCreditOrder} />}>
                   
                    <Table className="shipments-table-top-header">
                        {

                            (shipment.debited_shipment || shipment.credited_shipment) ?
                                <React.Fragment>
                                    <Thead>
                                        <tr>
                                            {shipment.debited_shipment &&
                                                <Th compress>{t(`ORDER_TABLE_HEADER_DEBIT_INVOICE_NO`)}</Th>}
                                            {shipment.credited_shipment &&
                                                <Th compress>{t(`ORDER_TABLE_HEADER_CREDIT_INVOICE_NO`)}</Th>}

                                        </tr>
                                    </Thead>
                                    <TBody >
                                        <Tr>
                                            {shipment.debited_shipment &&
                                                <Td>{shipment.debited_shipment.invoice_no}</Td>}
                                            {shipment.credited_shipment &&
                                                <Td>{shipment.credited_shipment.invoice_no}</Td>}
                                            {shipment.credited_shipment && <Td></Td>}
                                        </Tr>
                                    </TBody>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Thead>
                                        <tr>
                                            <Th className="alert-error" title="Arvato communication error probable cause of this">
                                                Missing debited/credited invoice, please contact Wasa Kredit support at ehandel@wasakredit.se.
                                            </Th>
                                        </tr>
                                    </Thead>
                                </React.Fragment>
                                }
                    <TFoot>
                        {!shipment.comments
                            ? null
                            : (<tr><TdComments>{shipment.comments}</TdComments></tr>)}
                    </TFoot>
                </Table>
                        
                <Table className="shipments_cart_items_table">
                    <Thead> 
                        <tr>
                            <Th className="subtable-th" compress>{t(`ORDER_DETAILS_PRODUCT_NAME`)}</Th>
                            <Th compress noWrap>{t(`ORDER_DETAILS_PRODUCT_ID`)}</Th>
                            <Th compress noWrap centerAlign>{t(`ORDER_DETAILS_QUANTITIY`)}</Th>
                            <Th compress noWrap rightAlign>{t(`ORDER_DETAILS_PRICE`)}</Th>
                            </tr>

                    </Thead>
                    <TBody >
                        {shipment.cart_items.map(
                            (item, ii) => {
                                return (
                                    <Tr key={item.id + "_" + ii}>
                                        <Td>
                                            {item.product_name}
                                        </Td>
                                        <Td compress noWrap>
                                            {item.product_id}
                                        </Td>
                                        <Td compress noWrap centerAlign>
                                            {item.quantity}
                                        </Td>
                                        <Td compress noWrap rightAlign>
                                            {formatCurrency(item.price_ex_vat.amount, item.price_ex_vat.currency)}
                                        </Td>
                                    </Tr>
                                );
                            })}
                    </TBody>
                    <TFoot>
                        {!shipment.comments
                            ? null
                            : (<tr><TdComments>{shipment.comments}</TdComments></tr>)}
                    </TFoot>
                    </Table>
                    {
                        shipment.debited_shipment && shipment.debited_shipment.payments.length !==0 ?
                            <Table className="shipments_cart_items_table">
                                <Thead>
                                    <tr>
                                        <Th className="subtable-th" compress>Betalningar</Th>
                                        <Th compress noWrap>Datum</Th>
                                        <Th compress noWrap>Belopp</Th>
                                    </tr>
                                </Thead>
                                    <TBody>
                                    {shipment.debited_shipment.payments.map((x, i) =>
                                        <Tr key={'payd_'+i }>
                                                <Td>Betalning inkommen</Td>
                                                <Td>{format.dateWithTime(x.paid_at)}</Td>
                                                <Td>{formatCurrency(x.amount, "SEK") }</Td>
                                            </Tr>

                                        )}
                                    </TBody>


                            </Table>
                    : null
                    }
                    </AccordionWrapper>

            </div>))}
        </div>
    )
}
export default Shipments;




