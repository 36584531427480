import styled from "styled-components";
import { SecondaryButton } from "../styled/Buttons";

const ChangePasswordButton = styled(SecondaryButton) `
  height: 50px;
  white-space: nowrap;
  margin-top: 31px;
  margin-bottom: 10px;
  flex-shrink: 0;
  flex-grow: 0;
`;

export default ChangePasswordButton;
