const colors = {
  readyToShip: '#2A7FD4',
  shipped: '#B8BE34',
  completed: '#8DBA84',
  pending: '#FDB514',
  pending_signing: '#FDB514',
  pending_approval: '#ED7721', 
  canceled: '#CE161D',
  error: '#E30613',
}

export default colors
