import React from "react";
import styled from "styled-components";
import { Input } from "../styled";

const CreateItemInput = styled(Input).attrs([

])`
    padding: 5px;
`;

export default CreateItemInput;