import styled from "styled-components";

const CreateUserForm = styled.form`
  display: flex;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export default CreateUserForm;
