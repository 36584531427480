import React from 'react';
import t from "../../translation";
import { Row, Item, Fixed } from "./OrderTop";
import Address from "./Address";
import AddressTitle from "./AddressTitle";

const OrderDetailsAddresses = (props) => {
    return (
        <React.Fragment>
            <Row>
                <Item width="100%" top="15px">
                    <AddressTitle>{t(`ORDER_DETAILS_SHIPPING_ADDRESS`)}</AddressTitle>
                    <Address data-hj-suppress id={"order-details-delivery-address"}>
                        {props.order.delivery_address.company_name} <br />
                        {props.order.delivery_address.street_address} <br />
                        {`${props.order.delivery_address.postal_code} ${props.order.delivery_address.city}`}
                    </Address>
                </Item>
                <Item width="100%" top="15px">
                    <AddressTitle>{t(`ORDER_DETAILS_RECIPIENT_HEADER`)}</AddressTitle>
                    <Address>
                        <Fixed>
                            <strong>
                                {t(`ORDER_DETAILS_RECIPIENT_NAME`)}
                            </strong>
                        </Fixed>
                        <span data-hj-suppress id={"order-details-recipient-name"}>
                            {props.order.recipient_name}
                        </span>
                        <br />
                        <Fixed>
                            <strong>
                                {t(`ORDER_DETAILS_RECIPIENT_PHONE`)}
                            </strong>
                        </Fixed>
                        <span data-hj-suppress id={"order-details-recipient-phone"}>
                            {props.order.recipient_phone}
                        </span>
                        <br />
                    </Address>
                </Item>
            </Row>
        </React.Fragment>
    )
}
export default OrderDetailsAddresses;




