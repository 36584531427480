import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Input,
  NoWrap,
  Td,
  Tr,
  ErrorMessage,
  InformationMessage
} from "../styled";

import { updateUser } from "../../services/api";

import { validation } from "../../utils";
import t from "../../translation";
import { SecondaryButton, PrimaryButton } from "../styled/Buttons";

class UserRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: props.userId,
      name: props.name,
      email: props.email,
      editingName: "",
      editingEmail: "",
      isEditing: false,
      isSaving: false,
      suppressErrorMessages: true,
    };
  }

  componentDidMount() {
  }

  onEditClick() {
    const currentState = this.state;
    this.setState({
      errorMessage: null,
      editingName: currentState.name,
      editingEmail: currentState.email,
      isEditing: true,
    }, () => {
      this.editUserName.focus();
    });
  }

  onAbortClick() {
    this.setState({
      errorMessage: null,
      isEditing: false,
      suppressErrorMessages: true,
    });
  }

  onSave() {

    if (!validation.isString(this.state.editingName)) {
      this.setState({ suppressErrorMessages: false });
      return;
    }

    this.setState({
      errorMessage: null,
      isSaving: true,
      suppressErrorMessages: true
    });

    const currentState = this.state;

    updateUser(
      currentState.userId,
      currentState.editingName,
      currentState.editingEmail
    ).then((res) => {
      this.setState({
        name: res.name,
        email: res.email,
        isEditing: false,
        isSaving: false,
      });
    }).catch((err) => {
      this.setState({
        isSaving: false,
        errorMessage: "Något gick fel när vi skulle spara användaren",
        suppressErrorMessages: false,
      });

      console.log(err)
    });
  }

  keyPress(e) {
    switch (e.keyCode) {
      case 13: // ENTER-key
        this.onSave();
        break;
      case 27: // ESC-key
        this.onAbortClick();
        break;
      default:
    }
  }

  onNameChange(value){
    this.setState({ editingName: value });
  }

  onEmailChange(value){
    // this.setState({ editingEmail: value });
  }

  render() {
    const {
      userId,
      isEditing,
      isSaving,
      name,
      email,
      editingName,
      editingEmail,
      suppressErrorMessages
    } = this.state;



    return (
      <Tr noHover id={`user-row-${userId}`}>
        <Td noWrap topAlign={ isEditing } className={"user-cell-name"}>
          <Input
            ref={ (ref) => { this.editUserName = ref }}
            value={ isEditing ? editingName : name }
            onChange={ (e) => this.onNameChange(e.target.value) }
            readOnly={ !isEditing }
            disabled={ isSaving || !isEditing }
            invalid={ !suppressErrorMessages && !validation.isString(this.state.editingName) }
            onKeyDown={ this.keyPress.bind(this) }
          />
        <ErrorMessage visible={ !suppressErrorMessages && !validation.isString(this.state.editingName) }>{ t("VALIDATION_ERROR_NAME") }</ErrorMessage>
        </Td>
        <Td topAlign={ false /* isEditing */ } className={"user-cell-email"}>
          <Input
            value={ isEditing ? editingEmail : email }
            onChange={ (e) => this.onEmailChange(e.target.value) }
            readOnly={ true /* !isEditing */ }
            disabled={ true /* isSaving */ }
            invalid={ !suppressErrorMessages && !validation.isEmail(this.state.editingEmail) }
            onKeyDown={ this.keyPress.bind(this) }
          />
        <InformationMessage warningOnly={ true } visible={ isEditing }>{ t("USERS_TABLE_EMAIL_INFORMATION") }</InformationMessage>
        <ErrorMessage visible={ !suppressErrorMessages && !validation.isEmail(this.state.editingEmail) }>{ t("VALIDATION_ERROR_EMAIL") }</ErrorMessage>
        </Td>
        <Td rightAlign className={"user-cell-buttons"}>
          <NoWrap>
            {
              isEditing ?
                <span style={{ marginTop: "10px" }}>
                  <SecondaryButton className="user-button-abort" secondary onClick={ this.onAbortClick.bind(this) } disabled={ isSaving }>
                      { t(`USERS_TABLE_BUTTON_ABORT`) }
                  </SecondaryButton>&nbsp;
                  <PrimaryButton className="user-button-save" onClick={ this.onSave.bind(this) } disabled={ isSaving }>
                      {
                        isSaving ?
                        t(`USERS_TABLE_BUTTON_SAVING`) :
                        t(`USERS_TABLE_BUTTON_SAVE`)
                      }
                  </PrimaryButton>
                </span>
                :
                <span style={{ marginTop: "10px" }}>
                  <SecondaryButton className="user-button-edit" secondary onClick={ this.onEditClick.bind(this) }>
                    { t(`USERS_TABLE_BUTTON_EDIT`) }
                  </SecondaryButton>&nbsp;
                  <PrimaryButton className="user-button-delete" onClick={ this.props.onRemoveClick } disabled={ isSaving || this.props.removeButtonDisabled }>
                    { t(`USERS_TABLE_BUTTON_REMOVE`) }
                  </PrimaryButton>
                </span>
            }
          </NoWrap>
          <ErrorMessage tableCellMargin visible={ !suppressErrorMessages && this.state.errorMessage != null }>{ this.state.errorMessage }</ErrorMessage>
        </Td>
      </Tr>
    );
  }
}

UserRow.propTypes = {
  userId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string,
  onRemoveClick: PropTypes.func.isRequired,
  removeButtonDisabled: PropTypes.bool.isRequired,
}

export default UserRow;
