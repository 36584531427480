const addStatusFilterPropOnOrder = (order) =>{
    let orderFilterStatus = order.status;
    let agreementState = (order.agreement_state) ? order.agreement_state: (order.agreement) ? order.agreement.state: '';
    if(orderFilterStatus === 'canceled'){
      orderFilterStatus ="canceled"
    }
    else{
      if(agreementState  === 'partially-signed' || agreementState  === 'unsigned'){
          orderFilterStatus = "pending_signing";
      }
      if(agreementState === 'signed'  && order.status ==='pending'){
        orderFilterStatus = "pending_approval";
      }
    }
    order.orderFilterStatus = orderFilterStatus;
    return order;
  }
export default addStatusFilterPropOnOrder;