import React, {Component} from "react";
import {Switch, Route} from "react-router-dom";

import Orders from "./Orders.jsx";
import Users from "./Users";
import Profile from "./Profile";
import Security from './Security';
import Reports from '../Reports';
import NotFound from "./NotFound.jsx";
import LogOut from "./Logout"
import FAQ from "../FAQ";

export default class Main extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" component={Orders}/>
                <Route exact path="/users" component={Users}/>
                <Route exact path="/profile" component={Profile}/>
                <Route path="/security" component={Security}/>
                <Route path="/reports" render={(props) => <Reports {...props}/>}/>
                <Route path="/faq" component={FAQ}/>
                <Route exact path="/logout" component={LogOut}/>
                <Route path="*" component={NotFound}/>
            </Switch>
        );
    }
}
