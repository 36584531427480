import styled, { css } from "styled-components";

const Button = styled.button `
  color: #fff;
  font-family: "Intro Cond",Arial,"Helvetica Neue",sans-serif;
  -webkit-text-decoration: none;
  text-decoration: none;
  padding: 2px 13px 2px 13px;
  display: inline-block;
  font-family	"Intro Cond Bold", Arial, "Helvetica Neue", sans-serif;
  box-sizing: border-box;
  text-align: center;
  float: none;
  font-size: 1.125rem;
  line-height: 1.5rem;  
  padding: 6px 13px 6px 13px;
  &:hover{
    cursor: pointer;
  }
`; 



const PrimaryButton = styled(Button) `  
  background-color: #ad1015;
  border: 2px solid #ad1015;
  color: #ffffff;
  &:hover{
    background-color: #910d12;
    border-color: #910d12;
  }
  &:active{
    background-color: #7a0b16;
    border-color: #7a0b16;
  }
  &:disabled{
    color: #ccc;
    background-color: #aaa;
    border-color: #aaa;
    cursor: default;
  }  

  ${props =>
    props.large &&
    css`
      font-size: 1.125rem;
      padding: 10px 18px 10px 18px
    `};
`;

const SecondaryButton = styled(Button) `

  background-color: #ffffff;
  border: 2px solid #00427a;
  color: #00427a;
  &:hover{
    background-color: #ffffff;
    box-shadow: inset 0 0 0 1px #00427a;
  }
  &:active{
    background-color: #f2f7fb;
    box-shadow: inset 0 0 0 1px #00325c;
    border-color: #00325c;
    color: #00325c;
  }
  &:disabled{
    background-color: #fff;
    border-color: #888;
    color: #888;
    cursor: default;
  }    
  &:disabled:hover{
    box-shadow: inset 0 0 0 0px #00427a;
  }
  ${props =>
    props.oneLine &&
    css`
      white-space: nowrap;
    `};

    ${props =>
      props.fontSize &&
      css`
        font-size: ${props.fontSize};;
      `};

    ${props =>
      props.fixedHeight && css`
        height: ${props.fixedHeight};
        margin: 0 auto;
        padding: 5px
      `};
`;

const NavigationButton = styled(Button) `
  background-color: #fff;
  border: 2px solid #00427a;
  color: #00427a;
  margin-right: 30px;
  cursor: pointer;
  &:hover {
      box-shadow: inset 0 0 0 1px #00427a;
  }
`;

export { Button, PrimaryButton, SecondaryButton, NavigationButton };
