import React from 'react';
import t from "../../translation";
import { Row, Item } from "./OrderTop";
import Address from "./Address";
import AddressTitle from "./AddressTitle";

const OrderDetailsBillingAddress = (props) => {
    return (
        <Row>
            <Item width="100%" bottom="15px">
                <AddressTitle>{t(`ORDER_DETAILS_BILLING_ADDRESS`)}</AddressTitle>
                <Address data-hj-suppress id={"order-details-billing-address"}>
                    {props.order.billing_address.company_name} <br />
                    {props.order.billing_address.street_address} <br />
                    {`${props.order.billing_address.postal_code} ${props.order.billing_address.city}`}
                </Address>
            </Item>
            <Item width="100%" bottom="15px">
                <AddressTitle>{t(`ORDER_DETAILS_PURCHASER_HEADER`)}</AddressTitle>
                <a data-hj-suppress id={"order-details-purchaser-email"} href={"mailto:" + props.order.purchaser_email}>
                    {props.order.purchaser_email}
                </a>
            </Item>
        </Row>
    )
}
export default OrderDetailsBillingAddress;




