import React from 'react'
import PropTypes from 'prop-types'

import {
  SectionWrapper,
  Input,
  InputLabel,
  ErrorMessage,
  Small,
  Alert,
  InputWrapper
} from "../styled";

import Form from "./Form.jsx";
import ChangePasswordButton from "./ChangePasswordButton.jsx";
import TogglePasswordLink from "./TogglePasswordLink.jsx";

import t from "../../translation";
import { validation } from "../../utils";

class ChangePasswordForm extends React.Component {

  render () {
    const saving = this.props.saving;
    const suppressErrorMessages = this.props.suppressErrorMessages;
    const formErrorMessage = this.props.formErrorMessage;
    const showPassword = this.props.showPassword;
    const showPasswordChangeSuccess = this.props.showPasswordChangeSuccess;

    return (
      <SectionWrapper>
        <Form onSubmit={ this.props.onSubmit } disabled={ saving } noValidate>
          <InputWrapper>
            <TogglePasswordLink onClick={ this.props.onShowPasswordToggle }>
              { showPassword ? t("PROFILE_HIDE_PASSWORD") : t("PROFILE_SHOW_PASSWORD") }
            </TogglePasswordLink>
            <InputLabel for={"profile-new-password-input"}>
              { t("PROFILE_CHANGE_PASSWORD_HEADER") }
            </InputLabel>
            <Input
              id={"profile-new-password-input"}
              name={"profile-new-password-input"}
              placeholder={ t("PROFILE_PASSWORD_PLACEHOLDER") }
              value={ this.props.password }
              onChange={ (e) => this.props.onPasswordChange(e.target.value) }
              type={ showPassword ? "text" : "password" }
              disabled={ saving }
              invalid={ !suppressErrorMessages && !validation.isPassword(this.props.password) }
            />
            <ErrorMessage visible={ !suppressErrorMessages && !validation.isPassword(this.props.password) }>{ t("VALIDATION_ERROR_PASSWORD") }</ErrorMessage>
          </InputWrapper>
          <ChangePasswordButton id="profile-change-password" large secondary disabled={ saving }>
            { saving ? t("PROFILE_CHANGING_PASSWORD_BUTTON") : t("PROFILE_CHANGE_PASSWORD_BUTTON") }
          </ChangePasswordButton>
        </Form>
        <ErrorMessage block visible={ !suppressErrorMessages && formErrorMessage != null }>{ this.props.formErrorMessage }</ErrorMessage>
        <Small>{ t("PROFILE_PASSWORD_POLICY")}</Small>
        <Alert type="success" visible={ showPasswordChangeSuccess }>
          { t("PROFILE_PASSWORD_CHANGE_SUCCESS") }
        </Alert>
      </SectionWrapper>
    )
  }

}

ChangePasswordForm.propTypes = {
  password: PropTypes.string,
  onPasswordChange: PropTypes.func,
  onSubmit: PropTypes.func,
  saving: PropTypes.bool,
  suppressErrorMessages: PropTypes.bool,
  formErrorMessage: PropTypes.string,
  showPassword: PropTypes.bool,
  onShowPasswordToggle: PropTypes.func,
  showPasswordChangeSuccess: PropTypes.bool,
}

export default ChangePasswordForm;
