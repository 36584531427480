import styled, { css } from "styled-components";

const Link = styled.a `
  color: #fff;
  font-family: "Intro Cond", Arial, "Helvetica Neue", sans-serif;
  text-decoration: none;
  padding: 6px 13px 6px 13px;
  display: inline-block;
  box-sizing: border-box;
  float: right;
  &:hover{
    cursor: pointer;
  }
`;

const LogoutLink = styled(Link) `
  background-color: #007db0;
  &:hover{
    background-color: #006791;
  }
`;

const CommitLink = styled(Link) `
  text-align: center;
  float: none;
  background-color: #ad4848;
  &:hover{
    background-color: #8d2828;
  }
`;

const NavigationLink = styled.a `
  display: inline-block;
  color: #005aa0;
  margin-right: 30px;
  text-decoration: underline;
  font-family: "Intro Cond", Arial, "Helvetica Neue", sans-serif;
  cursor: pointer;
  &:hover {
      color: #002f54;
  }
`;

export { LogoutLink, CommitLink, NavigationLink };
