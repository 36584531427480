/* @flow */

import React from "react";

import "./styles.css";

type Props = { isButtonSpinner: boolean };

export default function Spinner(props: Props) {
  const { isButtonSpinner } = props;

  return isButtonSpinner
    ? <div className="lf-spinner lf-spinner-btn-primary">
        <svg
          className="lf-spinnger__svg"
          viewBox="0 0 105 105"
          x="0"
          y="0"
          role="animation"
        >
          <circle className="static-circle" r="45%" cx="50%" cy="50%" />
          <circle className="lf-spinner-dash" r="45%" cx="50%" cy="50%" />
        </svg>
      </div>
    : <div className="lf-spinner">
        <svg
          className="lf-spinnger__svg"
          viewBox="0 0 105 105"
          x="0"
          y="0"
          role="animation"
        >
          <circle className="static-circle" r="45%" cx="50%" cy="50%" />
          <circle className="lf-spinner-dash" r="45%" cx="50%" cy="50%" />
          <line
            className="lf-spinner-back-line"
            x1="50%"
            y1="50%"
            x2="100%"
            y2="50%"
          />
          <line
            className="lf-spinner-forward-line"
            x1="50%"
            y1="50%"
            x2="100%"
            y2="50%"
          />
        </svg>
      </div>;
}
