import styled, { css } from "styled-components";

const Th = styled.th `
  border: 0;
  padding: 0 27px;
  text-align: left;
  font-family: 'Intro Cond Bold', Arial, "Helvetica Neue", sans-serif;
  line-height: normal;
  font-size: 16px;
  font-weight: normal;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: #f3f3f3;

  ${props => props.compress && css`
    width: 1px;
  `};

  ${props => props.noWrap && css`
      white-space: nowrap;
  `};
`;

export default Th;
