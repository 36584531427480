import styled, { css } from "styled-components";

const InformationMessage = styled.span `
  color: #00427a;
  font-weight: 700;
  -webkit-transition: color 60ms linear;
  transition: color 60ms linear;
  font-family: 'Intro Cond',Arial,Helvetica Neue,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 4px;
  font-size: .875rem;
  line-height: 1.2;
  margin-top: 0px;
  display: none;
  margin-bottom: 0px;

  ${props => props.visible && css`
    display: block;
  `}
`;

export default InformationMessage;
