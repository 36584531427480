import { PrimaryButton } from "../styled/Buttons";
import styled from "styled-components";

const CreateTermsButton = styled(PrimaryButton) `
  height: 50px;
  white-space: nowrap;
  margin-top:10px;
  margin-bottom: 0px;
  flex-shrink: 0;
  flex-grow: 0;
`;

export default CreateTermsButton;
