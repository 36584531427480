import styled from "styled-components";

import searchIcon from "../../assets/search-icon.svg";

export default styled.input.attrs(props => ({
  type: "text",
  placeholder: props.placeholder || ""
})) `
  padding: 0.5em 0.5em 0.5em 2em;  
  width: 15em;
  float: right;
  font-size: 16px;
  background: #fff url('${ searchIcon }')  ;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px #D9D9D9;
  border-bottom: 2px solid #D9D9D9;
  background-position: 0.25em center;
  background-size: 1.5em;

  :focus {  
    border-bottom-color: #00427a;
    outline: none;
  }
`;
