import styled, { css } from "styled-components";

const Badge = styled.span `
  font-family: Arial;
  line-height: normal;
  font-size: 16px;
  background: #B1B3B6;
  color: #fff;
  padding: 0px 8px;
  margin: 0 20px 0px 6px;
  border-radius: 14px;
  font-size: 14px;
  font-weight: bold;
  top: -1px;
  position: relative;

  ${props => props.count > 0 && css`
    &:before {
      content: "${ props.count }";
      display: inline;
    }
  `};

  ${props => props.count === 0 && css`
    background: none;
    margin: 0 0px 0px 6px;
  `};
`;

export default Badge;
