import React, { useState, useEffect } from "react";
import t from "../../translation";
import {getClientId, getAccesToken} from "../../services/api";
import {
    Input,
    SectionWrapper,
    SectionHeader,
    SectionIntro,
    ErrorMessage
} from "../styled";
import CreateSecretForm from '../CreateSecret/CreateSecretForm';
import SuccessMessage from "../styled/SuccessMessage";
import CreateSecretContentWrapper from "../CreateSecret/CreateSecretContentWrapper";
import CreateSecretButton from "../CreateSecret/CreateSecretButton";

const TestSecret = (props) => {
    const [showValidationError, setShowValidationError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [partnerSecret,setPartnerSecret] = useState("");
    const [partnerClientId,setPartnerClientId] = useState("");
    const [busy,setBusy] = useState(false);
    
    useEffect(()=>{
        getClientId().then(res=>{
            let clientId = res.client_id;
            if(clientId === null){
                setPartnerClientId("Kunde ej hämtas!");
                return;
            }
            if(clientId.length > 0){
                setPartnerClientId(clientId);
            }
        })

    },[])
    
    const submitTerms = (e)=>{
        setBusy(true);
        e.preventDefault();
        getAccesToken(partnerSecret).then(r=>{
            if(r.success){
                setShowValidationError(false);
                setShowSuccess(true);
                setPartnerSecret("");    
            } else {
                setShowValidationError(true);
                setShowSuccess(false);
            }
            
            setBusy(false);
        }).catch(e=>{
            setShowValidationError(true);
            setShowSuccess(false);
            setBusy(false);
            console.log("ClientId or Secret is not correct!");
        });
    }

    const handlePartnerSecretChange = (e) =>{
        const value= e.target.value;
        setPartnerSecret(value);
    }
    
    return (
        <SectionWrapper>
                <SectionHeader>{ t("TEST_SECRET_HEADER")}</SectionHeader>
                <SectionIntro>{ t("TEST_SECRET_DESCRIPTION")}.</SectionIntro>
                <SectionIntro>{ `${t("CLIENT_SECRET_CLIENT_ID")}: ${partnerClientId}`}</SectionIntro>
                <CreateSecretForm>
                    <CreateSecretContentWrapper>
                        <Input placeholder={t("SECRET")} onChange={handlePartnerSecretChange} value={partnerSecret} text={partnerSecret} id={"partner_secret"}  label={t("SECRET")}/>
                        <CreateSecretButton large secondary  disabled={busy} id="test_secret_button" onClick={(event) => submitTerms(event)}>{t("MODAL_BUTTON_CONFIRM")}</CreateSecretButton>
                    </CreateSecretContentWrapper>
                </CreateSecretForm>
                
                <ErrorMessage block visible={ showValidationError }>{ t("TEST_SECRET_ERROR") }</ErrorMessage>
                <SuccessMessage block visible={ showSuccess }>{ t("TEST_SECRET_SUCCESS") }</SuccessMessage>
        </SectionWrapper>
    )
}
export default TestSecret;

