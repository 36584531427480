import styled from "styled-components";

const ModalClose = styled.button `
    position: absolute;
    top: 25px;
    right: 27px;
    opacity: .3;
    color: #00427a;
    transform: rotate(45deg);
    width: 26px;
    height: 26px;
    margin-right: -4px;
    border: 0;
    background-color: transparent;
    padding: 0;
    outline: none;

    &:hover {
      opacity: .8;
    }
    &:active {
      opacity: .4;
    }
    &:before, &:after {
      content: "";
      width: 18px;
      height: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -1px 0 0 -9px;
      background: currentColor;
    }
    &:before {
      width: 26px;
      height: 2px;
      margin: -1px 0 0 -13px;
    }
    &:after {
      width: 2px;
      height: 26px;
      margin: -13px 0 0 -1px;
    }
`;

export default ModalClose;
