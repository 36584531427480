import React, {Component} from "react";

import {Bordered, Fixed, Hr, Item, Row, Summary} from "./OrderTop";
import {Table, TBody, Td, TFoot, Th, Thead, Tr, TrShipping, TrTotalCost, TrTotalCostIncVat} from "./OrderTable";
import Address from "./Address";
import AddressTitle from "./AddressTitle";

import t from "../../translation";
import {format} from "../../utils";

import {PrimaryButton, SecondaryButton} from "../styled/Buttons";

import styles from './orderDetails.module.css';

import orderStatus from "../../enums/orderStatus";
import ItemStatus from "../styled/ItemStatus";
import {AuditLog} from "../AuditLog";

import ShippingModal from "./ShippingModal";
import ChangeModal from "./ChangeModal";
import OrderDetailsTopInfo from "./OrderDetailsTopInfo";
import OrderDetailsAddresses from "./OrderDetailsAddresses";
import OrderDetailsBillingAddress from "./OrderDetailsBillingAddress";
import OrderDetailsRemainingSignatories from "./OrderDetailsRemainingSignatories"
import Shipments from "./Shipments/Shipments";
import Cancellations from "./Cancellations";
import ModalWrapper from "../InvoiceOrderDetails/modalWrapper";
import AccordionWrapper from "../AccordionWrapper";

export default class OrderDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: {},
            expanded: {},
            comments: ""
        };
    }


    handleOnClick(e) {
        e.stopPropagation();
    }

    static getDerivedStateFromProps(props) {
        if (props.order === null || props.order === "loading") {
            return null;
        }

        let cancelled = props.order.cancellations
            .reduce((p, c) => [...p, ...c.cart_items], [])
            .reduce((p, c) => ({...p, [c.id]: c.quantity + (p[c.id] || 0)}), {});

        let shipped = props.order.shipments
            .reduce((p, c) => [...p, ...c.cart_items], [])
            .reduce((p, c) => ({...p, [c.id]: c.quantity + (p[c.id] || 0)}), {});

        return {
            noncanceledCartItems: props.order.cart_items
                .map(m =>
                    ({
                        ...m,
                        quantity: m.quantity - (cancelled[m.id] || 0),
                        shippedQuantity: shipped[m.id]
                    }))
                .filter(f => f.quantity > 0),
            shipments: props.order.shipments
                .slice()
                .sort((a, b) => new Date(b.shipped_at) - new Date(a.shipped_at)),
            cancellations: props.order.cancellations
                .slice()
                .sort((a, b) => new Date(b.canceled_at) - new Date(a.canceled_at)),
        };
    }

    static totalVat(items, order) {
        if (order.status === orderStatus.canceled) return 0;
        return items.reduce((p, c) => p + c.vat_amount.amount * c.quantity, parseFloat(order.shipping_cost_ex_vat.amount) * 0.25);
    }

    static totalPriceExclVat(items, order) {
        if (order.status === orderStatus.canceled) return 0;
        return items.reduce((p, c) => p + c.price_ex_vat.amount * c.quantity, parseFloat(order.shipping_cost_ex_vat.amount));
    }

    static totalPriceInclVat(items, order) {
        return OrderDetails.totalPriceExclVat(items, order)
            + OrderDetails.totalVat(items, order);
    }
    
    renderContent() {
        let order = this.props.order;
        if (order === null) {
            return <div className={styles.modalBody}>
                <div>
                    {t(`ORDER_DETAILS_ORDER_MISSING`)}
                </div>
            </div>
        }

        if (order === "loading") {
            return <div className={styles.modalBody}>
                <div>
                    {t(`ORDER_DETAILS_ORDER_LOADING`)}
                </div>
            </div>
        }
        const renderCommitLink = order.status === "ready_to_ship" ?
            <PrimaryButton id={"order-details-confirm-button"}
                           onClick={() => this.setState({shippingOrder: order})}>
                {t(`ORDER_DETAILS_SHIP_ITEMS`)}
            </PrimaryButton> :
            null;

        // Change order button
        const renderChangeLink =
            [orderStatus.shipped, orderStatus.canceled, orderStatus.completed].includes(order.status) ?
                null :
                <SecondaryButton id={"order-details-change-button"}
                                 onClick={() => this.setState({changeOrder: order})}>
                    {t(`ORDER_DETAILS_CHANGE_ITEMS`)}
                </SecondaryButton>;

        let nonCanceledItems = this.state.noncanceledCartItems;

        let shippingCost = format.currency(order.status !== orderStatus.canceled ? order.shipping_cost_ex_vat.amount : 0, order.shipping_cost_ex_vat.currency);
        let totalCostExVat = format.currency(OrderDetails.totalPriceExclVat(nonCanceledItems, order), order.total_price_ex_vat.currency);
        let totalVatCost = format.currency(OrderDetails.totalVat(nonCanceledItems, order), order.total_price_ex_vat.currency);
        let totalCost = format.currency(OrderDetails.totalPriceInclVat(nonCanceledItems, order), order.total_price_ex_vat.currency);

        return (
            <div className={styles.modalBody} onClick={this.handleOnClick.bind(this)}>
                <OrderDetailsTopInfo order={order}/>

                <div className="address-container">
                    <AccordionWrapper title="Adressuppgifter">
                    <OrderDetailsAddresses order={order}/>
                    <Hr/>
                    <OrderDetailsBillingAddress order={order}/>
                    </AccordionWrapper>
                </div>

                {order.pending_signers.length === 0 ? null :
                  <OrderDetailsRemainingSignatories order={order}/>
                }

                <Summary>{t(`ORDER_DETAILS_ORDER_SUMMARY_HEADER`)}</Summary>
                <Table id={"order-details-summary-table"}>
                    <Thead>
                        <tr>
                            <Th>{t(`ORDER_DETAILS_PRODUCT_NAME`)}</Th>
                            <Th compress>{t(`ORDER_DETAILS_PRODUCT_ID`)}</Th>
                            <Th compress>{t(`ORDER_DETAILS_QUANTITIY`)}</Th>
                            <Th compress noWrap rightAlign>{t(`ORDER_DETAILS_QUANTITY_SHIPPED`)}</Th>
                            <Th compress noWrap rightAlign>{t(`ORDER_DETAILS_PRICE_PER_ITEM`)}</Th>
                            <Th compress noWrap rightAlign>{t(`ORDER_DETAILS_PRICE`)}</Th>
                        </tr>
                    </Thead>
                    <TBody>
                        {nonCanceledItems
                            .map(
                                (item, index) =>
                                    (<Tr key={item.id + "_" + index}>
                                        <Td>
                                            {item.product_name}
                                        </Td>
                                        <Td compress noWrap>
                                            {item.product_id}
                                        </Td>
                                        <Td compress noWrap centerAlign>
                                            {item.quantity}
                                        </Td>
                                        <Td compress noWrap centerAlign>
                                            <ItemStatus item={item}/>
                                        </Td>
                                        <Td compress noWrap rightAlign>
                                            {format.currency(item.price_ex_vat.amount, item.price_ex_vat.currency)}
                                        </Td>
                                        <Td compress noWrap rightAlign>
                                            {format.currency(item.price_ex_vat.amount * item.quantity, item.price_ex_vat.currency)}
                                        </Td>
                                    </Tr>),
                            )}
                    </TBody>
                    <TFoot>
                        <TrShipping>
                            {this.renderCostColumns(t(`ORDER_DETAILS_SHIPPING_COST`), "order-details-total-shipping-cost-ex-vat", shippingCost)}
                        </TrShipping>
                        <TrTotalCost>
                            {this.renderCostColumns(t(`ORDER_DETAILS_PRICE_TOTAL`), "order-details-total-cost-ex-vat", totalCostExVat)}
                        </TrTotalCost>
                        <Tr>
                            {this.renderCostColumns(t(`ORDER_DETAILS_VAT`), "order-details-total-vat", totalVatCost)}
                        </Tr>
                        <TrTotalCostIncVat>
                            {this.renderCostColumns(t(`ORDER_DETAILS_PRICE_TOTAL_INC_VAT`), "order-details-total-cost-inc-vat", totalCost)}
                        </TrTotalCostIncVat>
                    </TFoot>
                </Table>

                {(!renderCommitLink && !renderChangeLink)
                    ? null
                    : (
                        <Row>
                            <Item top="15px" width="100%">
                                <div style={{margin: "20px"}}>
                                    {renderCommitLink} {renderChangeLink}
                                </div>
                            </Item>
                        </Row>
                    )}

                {!this.state.shipments.length
                    ? null
                    : (
                        <Shipments shipments={this.state.shipments}/>
                    )}

                {!this.state.cancellations.length
                    ? null
                    : (
                        <Cancellations cancellations={this.state.cancellations}/>
                    )}
                <AuditLog log={this.props.auditLog}/>

                <ShippingModal
                    order={this.state.shippingOrder}
                    onShip={(items, comments) => {
                        this.props.onConfirm(this.state.shippingOrder.id, items, comments);
                        this.setState({shippingOrder: null});
                    }}
                    onClose={() => this.setState({shippingOrder: null})}/>

                <ChangeModal
                    order={this.state.changeOrder}
                    onChangeOrder={(addedItems, canceledItems, comments, editedShippingAmount,origianalShippingAmount) => {
                        return this.props.onChangeOrder(this.state.changeOrder.id, addedItems, canceledItems, this.state.changeOrder.updated_date_utc, comments, editedShippingAmount,origianalShippingAmount).then(res => {
                            console.log('remove changes');
                            this.setState({changeOrder: null});
                            return res;
                        });
                    }}
                    onClose={() => this.setState({changeOrder: null})}/>
            </div>);
    }

    renderCostColumns(title, costId, cost) {
        return <>
            <Td>{title}</Td>
            <Td/>
            <Td/>
            <Td/>
            <Td/>
            <Td id={costId} rightAlign noWrap>
                {cost}
            </Td>
        </>;
    }

    render() {

        return (
            <ModalWrapper order={this.props.order}
                          onClose={this.props.onClose.bind(this)}>
                {this.renderContent()}

            </ModalWrapper>
        );
    }
}

