import styled, { css } from "styled-components";

const Td = styled.td `
  border: 0;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  padding: 10px 27px;

  font-family: Arial, "Helvetica Neue", sans-serif;
  line-height: normal;
  font-size: 16px;
  color: #222222;

  ${props => props.rightAlign && css`
    text-align: right;
  `};

  ${props => props.compress && css`
    width: 1px;
  `};

  ${props => props.noWrap && css`
    white-space: nowrap;
  `}

  ${props => props.centerAlign && css`
    text-align: center;
    width: 1px;
  `}

  ${props => props.topAlign && css`
    vertical-align: top;
  `}
`;

export default Td;
