import styled, { css } from "styled-components";
import theme from "../../theme";
import checkboxCheck from "../../assets/checkbox_check.svg";
import orderStatus from "../../enums/orderStatus";

const Input = styled.input `
  outline: none;

  &[type=checkbox] {
    appearance: none;
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: 0 4px -3px 0;
    background-color: #F3F3F3;
    border: 2px solid #D9D9D9;
    box-sizing: border-box;
  }

  &[type=checkbox]:checked {
    border: 0;
    background-image: url(${checkboxCheck});
    background-position: center;
    background-repeat: no-repeat;

    ${props => props.status === orderStatus.pending_signing && css`
        background-color: ${theme.pending_signing};`}
    ${props => props.status === orderStatus.pending_approval && css`
        background-color: ${theme.pending_approval};`}
    ${props => props.status === orderStatus.readyToShip && css`
        background-color: ${theme.readyToShip};`}
    ${props => props.status === orderStatus.completed && css`
        background-color: ${theme.completed};`}
    ${props => props.status === orderStatus.shipped && css`
        background-color: ${theme.shipped};`}
    ${props => props.status === orderStatus.canceled && css`
        background-color: ${theme.canceled};`}
  }
`;

export default Input;
