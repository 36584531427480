import React from 'react';

import { Tr, Td } from "../OrderDetails/OrderTable";
import ShippedIcon from "./ShippedIcon";
import { format } from "../../utils";

const ShippedArticles = (props) => {
    return (
        <React.Fragment> 
        { props.shippedArticles
                    .map((item, index) => 
                        (<Tr key={index} >
                            <Td className="shippedText">{ item.product_name }</Td>
                            <Td className="shippedText" compress noWrap>{ item.product_id }</Td>
                            <Td className="shippedText" noWrap centerAlign> {item.quantity }</Td>
                            <Td className="shippedText" compress noWrap rightAlign>{ format.currency(item.price_ex_vat.amount, item.price_ex_vat.currency) }</Td>
                            <Td className="shippedText" compress noWrap rightAlign>{ format.currency(item.price_ex_vat.amount*item.quantity, item.price_ex_vat.currency) }</Td>
                            <Td className="shippedText" compress noWrap rightAlign>{ <ShippedIcon />}</Td>
                        </Tr>)) 
                            }
        </React.Fragment>
    )
}
export default ShippedArticles;




