import React,  {  useState } from 'react';
import t from "../../translation";
import { resendSingEmailLink } from "../../services/api";
import { SecondaryButton } from "../styled/Buttons";
import { Row,  Item, Fixed } from "./OrderTop";
import CreateItemInputWrapper from "../NewItemForm/CreateItemInputWrapper";
import CreateItemForm from "../NewItemForm/CreateItemForm";
import InputLabel from "../styled/InputLabel";
import CreateItemInput from "../NewItemForm/CreateItemInput";
import Address from "./Address";
import AddressTitle from "./AddressTitle";
import { ErrorMessage } from "../styled";
import { validation } from "../../utils/";

const OrderDetailsRemainingSignatories = (props) => {
    const [isMailSent, setIsMailSent] = useState(false);
    const [recipientName, setRecipientName] = useState("");
    const [recipientEmail, setRecipientEmail] = useState("");
    const [suppressValidationError, setSuppressValidationError] = useState(true);
    const [showSendErrorMessage, setShowSendErrorMessage] = useState("");
    
    const SendEmail = (e) =>
    {
        e.preventDefault();
        resendSingEmailLink(props.order.id, recipientName, recipientEmail)
            .then((res) => {
                setIsMailSent(true);
                setTimeout(() => {setIsMailSent(false)}, 3000);
                setRecipientName("");
                setRecipientEmail("");
                setShowSendErrorMessage(false);
                setSuppressValidationError(true);
                })
                .catch(err => {
                    setIsMailSent(false);
                    setShowSendErrorMessage(true)
                    console.log(err);
                });
    };
   
    const OnNameChange = (value) =>
    {
        setSuppressValidationError(false);
        setRecipientName(value);
    };

    const OnEmailChange = (value) =>
    {
        setSuppressValidationError(false);
        setRecipientEmail(value);
    };
    
    return (
        <Row>
        <Item width="100%" top="15px">
            <AddressTitle>{t(`ORDER_DETAILS_REMAINING_SIGNATORIES`)}</AddressTitle>
            {props.order.pending_signers
                .map((item, index) => (
                    <Address key={index}>
                        <Fixed>
                            <strong>
                                {t(`ORDER_DETAILS_REMAINING_SIGNATORIES_NAME`)}
                            </strong>
                        </Fixed>
                        <span data-hj-suppress
                              id={"order-details-remaining-signatories-name-" + index}>
                                            {item.name}
                        </span>
                        <br/>
                        <Fixed>
                            <strong>
                                {t(`ORDER_DETAILS_REMAINING_SIGNATORIES_EMAIL`)}
                            </strong>
                        </Fixed>
                        <span data-hj-suppress
                              id={"order-details-remaining-signatories-email-" + index}>
                                            {item.email}
                        </span>
                        <br/>
                        <br/>
                    </Address>
                ))}
        </Item>
        <Item width="100%" top="15px">
            <AddressTitle>{t(`ORDER_DETAILS_REMAINING_SIGNATORIES_REMINDER_HEADER`)}</AddressTitle>
            <div>
                <CreateItemForm onSubmit={ e => SendEmail(e) } disabled={ isMailSent } noValidate>
                    <CreateItemInputWrapper width="40%">
                        <InputLabel>{ t(`ORDER_DETAILS_REMAINING_SIGNATORIES_NAME`) }</InputLabel>
                        <CreateItemInput
                            id="recipient_name"
                            value={ recipientName }
                            onChange={ (e) => OnNameChange(e.target.value) }
                            disabled={ isMailSent }
                            invalid={ !suppressValidationError && !validation.isString(recipientName, 1, 50) }
                        />
                    </CreateItemInputWrapper>
                    <CreateItemInputWrapper width="40%">
                        <InputLabel>{ t(`ORDER_DETAILS_REMAINING_SIGNATORIES_EMAIL`) }</InputLabel>
                        <CreateItemInput
                            id="recipient_email"
                            value={ recipientEmail }
                            onChange={ (e) => OnEmailChange(e.target.value) }
                            disabled={ isMailSent }
                            invalid={ !suppressValidationError && !validation.isEmail(recipientEmail) }
                        />
                    </CreateItemInputWrapper>
                    <div className="padding">
                        <SecondaryButton fontSize="14px" fixedHeight="35px" oneLine id="send_mail_btn" 
                            disabled={ !validation.isEmail(recipientEmail) 
                                        || !validation.isString(recipientName, 1, 50)}>
                            { isMailSent ? t(`ORDER_DETAILS_REMAINING_SIGNATORIES_SEND_BTN_SENT`) : t(`ORDER_DETAILS_REMAINING_SIGNATORIES_SEND_BTN_SEND`) }
                        </SecondaryButton>
                    </div>
                </CreateItemForm>
                <ErrorMessage visible={showSendErrorMessage}>{ t(`ORDER_DETAILS_REMAINING_SIGNATORIES_SEND_EMAIL_ERROR`) }</ErrorMessage>
            </div>
            
        </Item>
        
    </Row>
    )
}
export default OrderDetailsRemainingSignatories;




