import styled from "styled-components";

const CreateSecretContentWrapper = styled.div`
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  position: relative;
`;

export default CreateSecretContentWrapper;
