import React, { Component } from "react";

import { ContentWrapper } from "../styled";
import UsersTable from "../UsersTable";
import NewUserForm from "../NewUserForm";
import UserRow from "./UserRow.jsx";
import Modal from "../Modal";

import { validation } from "../../utils/";

import { getUsers, createUser, removeUser } from "../../services/api";
import { AuthService } from '../../services/authService';
import t from "../../translation";

export default class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUserId: null,
      newUserName: "",
      newUserEmail: "",
      newUserFormErrorMessage: null,
      suppressNewUserErrorMessages: true,
      creatingNewUser: false,
      loadingUsers: true,
      loadingUsersError: null,
      users: [],
      removalConfirmedOnUserId: null,
      modalVisible: false,
      modalTexts: [""],
      modalHeader: "",
    };

    this.authService = new AuthService();
    this.authService.profile().then(res =>
      {
        this.setState({loggedInUserId: res.user_id});
      }).catch(err => {
        console.log(err)
    });
  }

  componentDidMount() {
    getUsers().then((res) => {
      this.setState({
        loadingUsers: false,
        users: res
      });
    }).catch((err) => {
      this.setState({
        loadingUsers: false,
        loadingUsersError: `Något gick fel när vi försökte ladda användarna.`
      });
    });
  }

  onNewUserNameChange(value) {
    this.setState({ newUserName: value })
  }

  onNewUserEmailChange(value) {
    this.setState({ newUserEmail: value })
  }

  onNewUserSubmit(e) {
    e.preventDefault();

    if (!validation.isEmail(this.state.newUserEmail) ||
        !validation.isString(this.state.newUserName, 3, 100)
      ){
        this.setState({ suppressNewUserErrorMessages: false });
      return;
    }

    this.setState({
      creatingNewUser: true,
      newUserFormErrorMessage: null
    });

    createUser(this.state.newUserName.trim(), this.state.newUserEmail.trim()).then((res) => {
      let users = this.state.users;

      users.push({
        id: res.id,
        name: res.name,
        email: res.email,
      });

      this.setState({
        creatingNewUser: false,
        newUserName: "",
        newUserEmail: "",
        users,
        suppressNewUserErrorMessages: true,
        newUserFormErrorMessage: null,
      });

      this.newUserForm.focusName();
    }).catch((err) => {
      this.setState({
        creatingNewUser: false,
        suppressNewUserErrorMessages: false,
        newUserFormErrorMessage: `Det gick inte att skapa en användare`
      });
    });
  }

  onRemoveUserClick(user) {
    if (this.state.loggedInUserId === user.id) {
      return;
    }

    this.setState({
      removalConfirmedOnUser: user,
      modalVisible: true,
      modalHeader: t("MODAL_HEADER_REMOVE_USER"),
      modalTexts: [t("MODAL_TEXT_REMOVE_USER")],
    });
  }

  closeModal() {
    this.setState({
      removalConfirmedOnUser: null,
      modalVisible: false,
    });
  }

  removeConfirmedUser(){
    this.setState({ modalVisible: false });

    const user = this.state.removalConfirmedOnUser;

    removeUser(user.id).then((res) => {
      let users = this.state.users;
      users.splice(users.indexOf(user), 1);

      this.setState({ users });
    }).catch((err) => {
    });
  }

  render() {
    const { loggedInUserId } = this.state;

    return (
      <ContentWrapper>
        <NewUserForm
          ref={ (ref) => { this.newUserForm = ref }}
          name = { this.state.newUserName }
          email = { this.state.newUserEmail }
          onNameChange = { this.onNewUserNameChange.bind(this) }
          onEmailChange = { this.onNewUserEmailChange.bind(this)}
          onSubmit = { this.onNewUserSubmit.bind(this) }
          creatingNewUser = { this.state.creatingNewUser}
          suppressErrorMessages = { this.state.suppressNewUserErrorMessages }
          formErrorMessage = { this.state.newUserFormErrorMessage }
        />
        <UsersTable
          isLoading={ this.state.loadingUsers }
          loadingUsersError={ this.state.loadingUsersError}
        >
          {
            this.state.users.map(
              user => {
                return (
                  <UserRow
                    key={ user.id }
                    userId={ user.id }
                    name={ user.name }
                    email={ user.email || "" }
                    onRemoveClick={ () => this.onRemoveUserClick(user) }
                    removeButtonDisabled={ loggedInUserId === user.id }
                  />
                );
              }
            )
          }
        </UsersTable>
        <Modal
          visible={this.state.modalVisible}
          onAbort={ this.closeModal.bind(this) }
          onConfirm={ this.removeConfirmedUser.bind(this) }
          texts={ this.state.modalTexts }
          header={ this.state.modalHeader}
        />
      </ContentWrapper>

    );
  }
}
