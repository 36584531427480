import React from "react";
import ContentWrapper from "../styled/ContentWrapper";
import SectionWrapper from "../styled/SectionWrapper";
import {SectionHeader, Table, TBody, Td, Tr} from "../styled";
import {SecondaryButton} from "../styled/Buttons";
import SectionIntro from "../styled/SectionIntro";

const FAQ = () => {
    function renderRow(name, ref) {
        return <Tr>
            <Td>{name}</Td>
            <Td rightAlign>
                <a href={ref} rel="noreferrer" target={"_blank"}><SecondaryButton>Öppna</SecondaryButton></a>
            </Td >
        </Tr>;
    }

    return (
        <ContentWrapper>
            <SectionWrapper>
                <SectionHeader>Vanliga frågor och svar</SectionHeader>
                <SectionIntro>Här hittar du steg-för-steg guider på de vanligaste frågorna som ställs till oss. Får du inte svar på din fråga, kontakta oss på <a href={"mailto:ehandel@wasakredit.se"}>ehandel@wasakredit.se</a> så hjälper vi dig.</SectionIntro>
                <Table>
                    <TBody>
                        {renderRow("Hur bekräftar jag en leverans?", `/static/faq/bekrafta_leverans.pdf`)}
                        {renderRow("Hur bekräftar jag en delleverans?", `/static/faq/delleverans.pdf`)}
                        {renderRow("Hur makulerar jag en order?", `/static/faq/makulera.pdf`)}
                        {renderRow("Hur skapar jag KlientID och API-nyckel?", `/static/faq/skapa_klientid_nyckel.pdf`)}
                        {renderRow("Hur ändrar jag i en order?", `/static/faq/andra_order.pdf`)}
                        {renderRow("Bekräfta leverans för ett fakturaköp", `/static/faq/bekrafta_leverans_fakturakop.pdf`)}
                        {renderRow("Kreditera ett fakturaköp", `/static/faq/kreditera_en_order_fakturakop.pdf`)}
                        {renderRow("Makulera ett fakturaköp", `/static/faq/makulera_order_fakturakop.pdf`)}
                        {renderRow("Ändra ett fakturaköp", `/static/faq/andra_order_fakturakop.pdf`)}
                    </TBody>
                </Table>
            </SectionWrapper>
        </ContentWrapper>
    );
}

export default FAQ;