import React, { Component } from "react";
import t from "../../../translation";
import { format } from "../../../utils";
import { validateUpdateCartItems } from "../../../services/api";

import TrashIcon from "../../ChangeModalComponents/TrashIcon";
import RemoveIcon from "../../ChangeModalComponents/RemoveIcon";
import Counter from "../../ChangeModalComponents/Counter"
import ShippedArticles from '../../ChangeModalComponents/ShippedArticles';
import Sum from '../../ChangeModalComponents/Sum';
import "./styles.css"


import { validation } from "../../../utils/";
import { Table, Thead, TBody, ThQuantity, Td, Th, Tr } from "../OrderTable";
import { ModalWindow, ModalOverlay } from "../OrderModal";
import { Summary } from "../OrderTop";
import { PrimaryButton, SecondaryButton } from "../../styled/Buttons";
import InputLabel from "../../styled/InputLabel";
import CreateItemInputWrapper from "../../NewItemForm/CreateItemInputWrapper";
import CreateItemForm from "../../NewItemForm/CreateItemForm";
import CreateItemInput from "../../NewItemForm/CreateItemInput";
import { ErrorMessage } from "../../styled";
import buttonStyles from '../closeButton.module.css';
import EditShipping from "./EditShipping";



export default class ChangeModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            order: props.order,
            suppressErrorMessages: true,
            suppressNewItemErrorMessages: true,
            creatingNewItem: false,
            newItemPrice:"",
            newItemQuantity:"",
            newItemArticleNumber:"",
            newItemProductName:"",
            addedItems: [],
            changeIsValid: false,
            validationErrorMessages: [],
            editedShippingAmount : null,
            isEditShippingEnabled : false
            
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.order) {
            return {
                order: null
            };
        }
        var shippingAmount = state.editedShippingAmount===null ?  parseFloat(props.order.shipping_cost_ex_vat.amount): state.editedShippingAmount;
        var shipped = props.order.shipments
            .reduce((p, c) => [ ...p, ...c.cart_items ], [])
            .reduce((p, c) => ({ ...p, [c.id]: c.quantity + (p[c.id] || 0) }), {});

        var cancelled = props.order.cancellations
            .reduce((p, c) => [ ...p, ...c.cart_items ], [])
            .reduce((p, c) => ({ ...p, [c.id]: c.quantity + (p[c.id] || 0) }), {});

        var remaining = props.order.cart_items
            .map(m => ({ ...m, quantity: m.quantity - (shipped[m.id] || 0) - (cancelled[m.id] || 0) }))
            .filter(f => f.quantity > 0);
        
        var shippedCartItemsUniqueId = props.order.cart_items
                .map(m => ({ ...m, quantity: (shipped[m.id] || 0) }))
            .filter(f => f.quantity > 0);

        var resetState = props.order !== state.order   
            ? { selected: remaining.reduce((p, c) => ({ ...p, [c.id]: c.quantity }), {}), 
                comments: "", 
                addedItems:[], 
                validationErrorMessages: [],
                suppressErrorMessages: true,
                suppressNewItemErrorMessages: true,
                newItemProductName:"",
                newItemArticleNumber:"",
                newItemPrice:"", 
                editedShippingAmount:null,
                newItemQuantity:""}
            : { selected: state.selected, comments: state.comments };



        return {
            order: props.order,
            editedShippingAmount : shippingAmount,
            remainingItems:  remaining,
            isEditShippingEnabled : props.order.shipments.length === 0,
            shippedItems: shippedCartItemsUniqueId,
            selectedItems: remaining
                .map(m => ({ ...m, quantity: resetState.selected[m.id] })),
            removedItems: remaining
                .map(m => ({ ...m, quantity: Math.abs(resetState.selected[m.id] - m.quantity) })),
            ...resetState                     
        };

    }

    onNewItemProductNameChange(value) {
        this.setState({ newItemProductName: value });
    }

    onNewItemArticleNumberChange(value) {
        this.setState({ newItemArticleNumber: value })
    }

    onNewItemNumberOfItemsChange(value) {
        this.setState({ newItemQuantity: value })
    }

    onNewItemPriceChange(value) {
        this.setState({ newItemPrice: value })
    }

    totalPriceExclVatExclShippingCost() {
        const addedItemsPrice = this.state.addedItems.reduce((p, c) => p + c.price_ex_vat.amount * c.quantity, 0);
        const selectedItemPrice = this.state.selectedItems.reduce((p, c) => p + c.price_ex_vat.amount * c.quantity, 0);
        const shippedItemsPrice = this.state.shippedItems.reduce((p, c) => p + c.price_ex_vat.amount * c.quantity, 0);
        return addedItemsPrice + selectedItemPrice + shippedItemsPrice;
    }

    totalVatAmount() {
        const addedItemsVat = this.state.addedItems.reduce((p, c) => p + c.vat_amount.amount * c.quantity, 0);
        const selectedItemVat = this.state.selectedItems.reduce((p, c) => p + c.vat_amount.amount * c.quantity, 0);
        const shippedItemsVat = this.state.shippedItems.reduce((p, c) => p + c.vat_amount.amount * c.quantity, 0);
        const shippingCostVat = parseFloat(this.state.editedShippingAmount) * 0.25
        return addedItemsVat + selectedItemVat + shippedItemsVat + shippingCostVat;
    }

    totalPriceInclVat() {
        return this.totalVatAmount() + this.totalPriceExclVat();
    }

    totalPriceExclVat() {
        const totalExclVatAndShippingCost = this.totalPriceExclVatExclShippingCost();
        const shippingCost = parseFloat(this.state.editedShippingAmount);
        return totalExclVatAndShippingCost + shippingCost;
    }

    onDeleteAddedItem(index) {
        var addedItems = [...this.state.addedItems];
        
        if (index !== -1) {
            addedItems.splice(index, 1);
            this.setState ({addedItems},()=> {
                this.validateOrderChange();
            });        
        }
        
    }

    onCancelItem(itemId) {
        var selected = this.state.selected;
        selected[itemId] = 0;
        this.setState ({selected},
            ()=> {this.validateOrderChange();});                
    }

    onChangeShipping(value){
        if(isNaN(value)){
            return;
        }
        
        var newValue = value.length === 0 ? 0 : parseFloat(Math.abs(value));
        this.setState({editedShippingAmount: newValue},() => {this.validateOrderChange()});
    }

    getItemSelectedQuantity(itemId){
        const itemOriginalQuantity = this.state.remainingItems.find(({id}) => id === itemId).quantity;
        let itemRemovedQuantity = 0;
        if (this.state.removedItems !== undefined && this.state.removedItems.length !== 0) {
            let itemRemoved = this.state.removedItems.find(({id}) => id === itemId);
            if (itemRemoved !== undefined)
            {
                itemRemovedQuantity = itemRemoved.quantity;
            }
        };
        
        return itemOriginalQuantity - itemRemovedQuantity;
    }


    async cancelRemainingPartOfOrder()
    {
        this.setState(state => ({
            ...state,
            addedItems: [],
            selected: this.state.remainingItems.reduce((p, c) => ({ ...p, [c.id]: 0 }), {})
        }), () => {this.validateOrderChange()});
        
    }

    async changeOrder(){
        try {

            await this.props.onChangeOrder(this.state.addedItems, this.state.removedItems, this.state.comments, this.state.editedShippingAmount,this.state.order.shipping_cost_ex_vat.amount);                
        }
        catch (err) {                
            this.setState({
                changeIsValid: false,
                validationErrorMessages: [`Oväntat fel uppstod ${this.wrapOrEmpty('(', err.Error || err, ')')} ladda om sidan`],
                suppressErrorMessages: false
            });                          
        }         
    }

    async changeOrderWithValidation() {
        var validResult = await this.validateOrderChange();        

        if (validResult) {            
            this.changeOrder();
        }
        else {
            console.warn("order is an invalid state!");
        }        
    }

    wrapOrEmpty(left, text, right) {
        if (text) {
            return left + text + right;
        }
        return "";
    }

    async validateOrderChange() {
        // Is order actually changed?
        const changesOccuredOnShipping = ()=>{
            return parseFloat(this.state.order.shipping_cost_ex_vat.amount) !== parseFloat(this.state.editedShippingAmount);
        }

        if (this.state.addedItems.length === 0 && this.state.removedItems.filter(f => f.quantity > 0).length === 0  && !changesOccuredOnShipping() ) {
            this.setState({ 
                changeIsValid:false,
                validationErrorMessages: []
             })
             return false;
        }
        
        const result = await validateUpdateCartItems(this.state.order.id, this.state.addedItems, this.state.removedItems, this.state.order.updated_date_utc, this.state.comments, this.state.editedShippingAmount)
            .then((res) => {
                this.setState({
                    changeIsValid: res.is_valid_change,
                    validationErrorMessages: res.validation_messages
                }, () => {
                    if (this.state.validationErrorMessages.length !== 0) {
                        this.setState({ suppressErrorMessages: false });                        
                    }                    
                });

                return res.is_valid_change;
            })
            .catch((err) => {
                console.log(err);
                return false;
            });

        return result;
    }

    onAddItemSubmit(e) {
        e.preventDefault();

        if (!validation.isString(this.state.newItemProductName,1,100) ||
        !validation.isString(this.state.newItemArticleNumber, 1, 100) ||
        !validation.isNumber(this.state.newItemQuantity, 1, 999) ||
        !validation.isDecimal(this.state.newItemPrice, 1, 999999)) 
        {
            this.setState({ 
                suppressErrorMessages: false,
                suppressNewItemErrorMessages : false
             });
            return;
        }

        this.setState({
            creatingNewItem: true,
          });

        this.setState(state => {
            const newItem = {
                product_id: this.state.newItemArticleNumber,
                product_name: this.state.newItemProductName,
                price_ex_vat: {
                    amount: Number(this.state.newItemPrice),
                    currency: 'SEK',
                },
                quantity: Number(this.state.newItemQuantity),
                vat_percentage: 25,
                vat_amount : {
                    amount: Number(this.state.newItemPrice)*0.25,
                    currency: 'SEK',
                    },
                payment_method: null,
                image_url: null,
            }
            state.addedItems.push(newItem)
            }, () => {
                this.validateOrderChange();
        });

        // Reset input and errors
        this.setState({
            creatingNewItem: false,
            suppressNewItemErrorMessages: true,
            newItemArticleNumber:"",
            newItemProductName:"",
            newItemPrice:"",
            newItemQuantity:"",
        });
    }

    render() {
        if (this.state.order == null) {
            return null;
        }

        return (
            <ModalOverlay onClick={ (ev) => { ev.stopPropagation(); this.props.onClose(); } }>
                <ModalWindow onClick={ (ev) => { ev.stopPropagation(); } }>
                                       
                    <Summary>{ t(`ORDER_DETAILS_CHANGE_ITEMS_HEADER`) }</Summary>
                   
                    <Table>
                        <Thead>
                            <tr>                            
                                <Th>{ t(`ORDER_DETAILS_PRODUCT_NAME`) }</Th>
                                <Th compress>{ t(`ORDER_DETAILS_PRODUCT_ID`) }</Th>
                                <ThQuantity compress centerAlign>{ t(`ORDER_DETAILS_QUANTITIY`) }</ThQuantity>
                                <Th compress noWrap rightAlign>{ t(`ORDER_DETAILS_PRICE_PER_ITEM`) }</Th>
                                <Th compress noWrap rightAlign>{ t(`ORDER_DETAILS_PRICE`) }</Th>
                                <Th compress noWrap rightAlign></Th>
                            </tr>
                        </Thead>
                        <TBody>
                            { this.state.remainingItems
                                .map((item, index) => 
                                    (<Tr key={ item.id + "_" + index } id={"remaining_" + item.id + "_" + index }>
                                        <Td>{ item.product_name }</Td>
                                        <Td compress noWrap>{ item.product_id }</Td>
                                        <Td noWrap centerAlign>{ 
                                            (<Counter 
                                                rangeFrom={ 0 }
                                                rangeTo={ item.quantity }
                                                value = {this.state.selected[item.id]}
                                                setter={ (v) => this.setState(state => ({ ...state, selected: { ...state.selected, [item.id]: v }}), () => {this.validateOrderChange();}) } /> ) 
                                            }</Td>
                                        <Td compress noWrap rightAlign>{ format.currency(item.price_ex_vat.amount, item.price_ex_vat.currency) }</Td>
                                        <Td compress noWrap rightAlign>{ format.currency(item.price_ex_vat.amount*this.getItemSelectedQuantity(item.id), item.price_ex_vat.currency) }</Td>
                                        <Td compress noWrap rightAlign data-item={item} onClick={ e => this.onCancelItem(item.id)}>{ <TrashIcon />}</Td>
                                    </Tr>) ) }
                                    <EditShipping 
                                        isEditShippingEnabled={this.state.isEditShippingEnabled} 
                                        onChangeShipping={(value)=> this.onChangeShipping(value)}
                                        editedShippingAmount={this.state.editedShippingAmount}
                                        />
                            <ShippedArticles shippedArticles = {this.state.shippedItems}/>
                            { 
                                this.state.addedItems
                                    .map((item, index) => 
                                        (<Tr key={index} id={"added_item_row_" + index }>
                                            <Td>{ item.product_name }</Td>
                                            <Td compress noWrap>{ item.product_id }</Td>
                                            <Td noWrap centerAlign> {item.quantity }</Td>
                                            <Td compress noWrap rightAlign>{ format.currency(item.price_ex_vat.amount, item.price_ex_vat.currency) }</Td>
                                            <Td compress noWrap rightAlign>{ format.currency(item.price_ex_vat.amount*item.quantity, item.price_ex_vat.currency) }</Td>
                                            <Td compress noWrap rightAlign data-item={item} onClick={ e => this.onDeleteAddedItem(index)}>{ <RemoveIcon />}</Td>
                                        </Tr>)) }
                                        
                                    <Tr>
                                        <Td className="noPadding" colSpan='6'>
                                            <CreateItemForm onSubmit={ this.onAddItemSubmit.bind(this) } disabled={ this.state.creatingNewItem } noValidate>
                                                <CreateItemInputWrapper width="80%">
                                                    <InputLabel>{ t("NEW_ITEM_NAME_PLACEHOLDER") }</InputLabel>
                                                    <CreateItemInput
                                                    id="add-item-poduct-name"
                                                    value={ this.state.newItemProductName }
                                                    onChange={ (e) => this.onNewItemProductNameChange(e.target.value) }
                                                    disabled={ this.state.creatingNewItem }
                                                    invalid={ !this.state.suppressNewItemErrorMessages && !validation.isString(this.state.newItemProductName,1,100) }
                                                    />
                                                </CreateItemInputWrapper>

                                                <CreateItemInputWrapper width="30%">
                                                    <InputLabel>{ t("NEW_ITEM_ARTICLE_NUMBER_PLACEHOLDER") }</InputLabel>
                                                    <CreateItemInput
                                                    id="add-item-article-number"
                                                    value={ this.state.newItemArticleNumber }
                                                    onChange={ (e) => this.onNewItemArticleNumberChange(e.target.value) }
                                                    disabled={ this.state.creatingNewItem }
                                                    invalid={ !this.state.suppressNewItemErrorMessages && !validation.isString(this.state.newItemArticleNumber, 1, 100) }
                                                    />
                                                </CreateItemInputWrapper>
                                                <CreateItemInputWrapper width="20%">
                                                    <InputLabel>{ t("NEW_ITEM_NUMBER_OF_ITEMS_PLACEHOLDER") }</InputLabel>
                                                    <CreateItemInput
                                                    id="add-item-number-of-items"
                                                    value={ this.state.newItemQuantity }
                                                    onChange={ (e) => this.onNewItemNumberOfItemsChange(e.target.value) }
                                                    disabled={ this.state.creatingNewItem }
                                                    invalid={ !this.state.suppressNewItemErrorMessages && !validation.isNumber(this.state.newItemQuantity, 1, 999) }
                                                    />
                                                </CreateItemInputWrapper>
                                                <CreateItemInputWrapper width="20%">
                                                    <InputLabel>{ t("NEW_ITEM_PRICE_PLACEHOLDER") }</InputLabel>
                                                    <CreateItemInput
                                                    id="add-item-price"
                                                    value={ this.state.newItemPrice }
                                                    onChange={ (e) => this.onNewItemPriceChange(e.target.value) }
                                                    type="text"
                                                    disabled={ this.state.creatingNewItem }
                                                    invalid={ !this.state.suppressNewItemErrorMessages && !validation.isDecimal(this.state.newItemPrice, 1, 999999) }
                                                    />
                                                </CreateItemInputWrapper>
                                                <div className="padding">
                                                    <SecondaryButton fontSize="14px" fixedHeight="35px" oneLine id="add-item-submit" disabled={ this.state.creatingNewItem }>
                                                        { this.state.creatingNewItem ? t("NEW_ITEM_CREATING_BUTTON") : t("NEW_ITEM_CREATE_BUTTON") }
                                                    </SecondaryButton>
                                                </div>
                                            </CreateItemForm>
                                        </Td>
                                    </Tr>

                                    

                                    {this.state.suppressErrorMessages ? null :
                                        <Tr>
                                            <Td colSpan='6'>
                                            <ErrorMessage visible={!this.state.suppressNewItemErrorMessages && !validation.isString(this.state.newItemProductName, 1, 100) }>{ t("VALIDATION_ERROR_PRODUCT_NAME") }</ErrorMessage>
                                            <ErrorMessage visible={!this.state.suppressNewItemErrorMessages && !validation.isString(this.state.newItemArticleNumber, 1, 100)}>{t("VALIDATION_ERROR_ARTICLE_NUMBER")}</ErrorMessage>
                                            <ErrorMessage visible={!this.state.suppressNewItemErrorMessages && !validation.isNumber(this.state.newItemQuantity, 1, 999)}>{t("VALIDATION_ERROR_NUMBER_OF_ITEMS")}</ErrorMessage>
                                            <ErrorMessage visible={!this.state.suppressNewItemErrorMessages && !validation.isDecimal(this.state.newItemPrice, 1, 999999)}>{t("VALIDATION_ERROR_PRICE")}</ErrorMessage>
                                            { this.state.validationErrorMessages
                                                    .map((item, index) => 
                                                        (<ErrorMessage key={index} visible={(this.state.validationErrorMessages.length !== 0)}>{item}</ErrorMessage>)) }
                                            </Td>
                                        </Tr>}

                                        <Tr>
                                        <Td className="noPadding" colSpan='6'>
                                               <div className="padding">
                                                    <SecondaryButton onClick={ () => this.cancelRemainingPartOfOrder() } fontSize="14px" fixedHeight="35px" oneLine id="cancel-all-items-btn">
                                                        { t("ORDER_DETAILS_CONFIRM_CANCEL_REMAINING") }
                                                    </SecondaryButton>
                                                </div>
                                        </Td>
                                    </Tr>
                            
                        </TBody>
                        <Sum
                            totalPriceExclVat={this.totalPriceExclVat()}
                            totalVatAmount={this.totalVatAmount()}
                            currency={this.state.order.total_price_ex_vat.currency}/>
                    
                    </Table>         

                    <div style={{ margin: "20px" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <input type="text" style={{ padding: "1em", margin: "1em 0 1em 0" }} defaultValue={this.state.comments} onChange={ e => this.setState({ "comments": e.target.value }) } placeholder={ t(`ORDER_DETAILS_COMMENTS_FIELD`) } />
                        </div>

                        <PrimaryButton 
                            id={"order-details-change-modal-button"} 
                            onClick={ () => this.changeOrderWithValidation() }
                            disabled={ !this.state.changeIsValid } > 
                            { t(`ORDER_DETAILS_CONFIRM_CHANGE`) }
                        </PrimaryButton>&nbsp;

                        <SecondaryButton 
                            secondary
                            id={"order-details-abort-change-modal-button"} 
                            onClick={ this.props.onClose.bind(this)}> 
                            { t(`MODAL_BUTTON_ABORT`) }
                        </SecondaryButton>
                    </div>

                </ModalWindow>
            </ModalOverlay>)
    }
}

