import React, { Component } from "react";
import orderStatus from "../../enums/orderStatus";
import t from "../../translation";

import Input from "./Input.jsx"
import Label from "./Label.jsx"
import Badge from "../styled/Badge.jsx"

export default class StatusToggle extends Component {

  renderOrderNumber(){
    if ((this.props.counts[this.props.status] || 0) > 0) {
      return (
        <Badge count={ this.props.counts[this.props.status] || 0 } />
      );
    }
  }

  render() {
    return (      
      <Label>
        <Input id={ this.props.id } type="checkbox" checked={ this.props.checked } status={ this.props.status } onChange={ () => this.props.toggle(this.props.status) } /> { t(orderStatus._translationKeys[this.props.status]) }
        { this.renderOrderNumber() }
      </Label>
    );
  }
}
