import React, {useState, useEffect} from 'react';
import styles from '../OrderDetails/orderDetails.module.css';
import t from "../../translation";
import { Hr, Item, Row} from "../OrderDetails/OrderTop";
import ModalWrapper from './modalWrapper';
import InvoiceOrderDetailsTopInfo from "./InvoiceOrderDetailsTopInfo";
import OrderDetailsAddresses from "../OrderDetails/OrderDetailsAddresses";
import Shipments from "./InvoiceShipments";
import Cancellations from "../OrderDetails/Cancellations";
import OrderSummary from './OrderSummary';
import orderStatus from "../../enums/orderStatus";
import {PrimaryButton, SecondaryButton} from "../styled/Buttons";
import InvoiceBillingAddress from './InvoiceBillingAddress';
import { cancelInvoiceOrder, shipInvoiceOrder, creditInvoiceShipment, cancelCartItems} from '../../services/api';
import InvoiceShippingModal from "./invoiceShippingModal";
import InvoiceCancelModal from "./invoiceCancelModal";
import {format} from "../../utils";
import {AuditLog} from "../AuditLog";
import AccordionWrapper from '../AccordionWrapper';
import InvoiceChangeModal from './invoiceChangeModal';

const InvoiceOrderDetails = (props) => {
    const [shippingOrder, setShippingOrder] = useState(null);
    const [cancelOrder, setCancelOrder] = useState(null);
    const [changingOrder, setChangingOrder] = useState(null);
    const handleOnClick = (e) => {
        e.stopPropagation();
    };
    const closeModal = () =>{

    }
    useEffect (() => {
        setShippingOrder(null);
        setCancelOrder(null);
        setChangingOrder(null);
    },[props]);

    const onConfirmOrder = (comments) => {
        return shipInvoiceOrder(shippingOrder.id, comments)
            .then((res) => {
                setShippingOrder(null);
                return new Promise((res, rej) => {
                    setTimeout(() => {
                        props.refreshOrder(props.order.id);
                    }, 1000);
                })
            })
            .catch((err) => {
                return new Promise((resolve, reject) => {
                    reject(err.message);
                })
            });
    };

    const onCancelOrder = (comments) => {
        return cancelInvoiceOrder(cancelOrder.id, comments)
            .then((res) => {
                setCancelOrder(null);
                return new Promise((res, rej) => {
                    setTimeout(() => {
                        props.refreshOrder(props.order.id);
                    }, 1000);
                })
            })
            .catch((err) => {
                return new Promise((resolve, reject) => {
                    reject(err.message);
                })
            });
    };

    const onCreditOrder = (shipmentId) => {
        return creditInvoiceShipment(props.order.id, shipmentId)
            .then((res) => {
                return new Promise((res, rej) => {
                    setTimeout(() => {
                        props.refreshOrder(props.order.id);
                    }, 1000);
                })
            })
            .catch((err) => {
                return new Promise((resolve, reject) => {
                    reject(err.message);
                })
            });
    };
    const onChangeOrder= (removedItems, comments)=> {
        return cancelCartItems(changingOrder.id, removedItems, comments)
            .then((res) => {        
                setChangingOrder(null);    
            closeModal();

            return new Promise((res, rej) => {
                setTimeout(() => {
                    props.refreshOrder(props.order.id);
                }, 1000);
            })
            })
            .catch((err) => {
                return new Promise((resolve,reject)=>{
                    reject(err.message);
                })
            });
      }
    return (
        <ModalWrapper
            order={props.order}
            onClose={props.onClose}>
            <div className={styles.modalBody} onClick={(e) => handleOnClick(e)}>
                {props.order === null || props.order === "loading" ?
                    props.order === "loading" ?
                        <div>{t(`ORDER_DETAILS_ORDER_LOADING`)}</div> :
                        <div>{t(`ORDER_DETAILS_ORDER_MISSING`)}</div>
                    : <React.Fragment>
                        <InvoiceOrderDetailsTopInfo order={props.order}/>

                        <div className="address-container">

                            <AccordionWrapper title="Adressuppgifter">
                            <OrderDetailsAddresses order={props.order}/>
                            <Hr/>
                            <InvoiceBillingAddress order={props.order}/>

                            </AccordionWrapper>
                        </div>

                        <OrderSummary order={props.order}/>
                        {
                            (props.order.status === "ready_to_ship") &&
                            (<Row>
                                <Item top="15px" width="unset">
                                    <div className="invoice-order-buttons-block">
                                        <PrimaryButton id={"order-details-confirm-button"}
                                                       onClick={() => setShippingOrder(props.order)}>
                                            {t(`ORDER_DETAILS_SHIP_ITEMS`)}
                                        </PrimaryButton>
            
                                    {

                                        [orderStatus.shipped, orderStatus.canceled, orderStatus.completed].includes(props.order.status) ?
                                        null :
                    
                                        <SecondaryButton id={"order-details-change-button"}
                                                        onClick={() => setChangingOrder(props.order)}>
                                            {t(`ORDER_DETAILS_CHANGE_ITEMS`)}
                                        </SecondaryButton>

                                    }

                                        <SecondaryButton id={"order-details-cancel-button"}
                                                         onClick={() => setCancelOrder(props.order)}>
                                            {t(`ORDER_DETAILS_CANCEL_ITEMS`)}
                                        </SecondaryButton>
                                    </div>
                                </Item>
                            </Row>)
                        }
                        {
                            shippingOrder !== null &&
                            <InvoiceShippingModal
                                order={shippingOrder}
                                onShip={onConfirmOrder}
                                onClose={() => setShippingOrder(null)}/>
                        }
                         {
                            changingOrder !== null &&
                            <InvoiceChangeModal
                                order={changingOrder}
                                onChange={onChangeOrder}
                                onClose={() => setChangingOrder(null)}/>
                        }
                        {props.order.shipments.length > 0
                        &&
                        (
                            <Shipments formatCurrency={format.currencyToFixed(2)} shipments={props.order.shipments} creditedShipments={props.order.credited_shipments} isInvoiceShipment= { true } onCreditOrder={onCreditOrder}/>
                        )}
                        {props.order.cancellations.length > 0
                        &&
                        (
                            <Cancellations formatCurrency={format.currencyToFixed(2)} cancellations={props.order.cancellations}/>
                        )}
                        <AuditLog log={props.auditLog}/>
                        {
                            cancelOrder !== null &&
                            <InvoiceCancelModal
                                order={cancelOrder}
                                onCancel={onCancelOrder}
                                onClose={() => setCancelOrder(null)}/>
                        }
                    </React.Fragment>
                }
            </div>
        </ModalWrapper>
    )
};
export default InvoiceOrderDetails;
