import styled from "styled-components";

const HeaderRightBlock = styled.div `
  float: right;
  display: inline-block;
  height: 21px;
  vertical-align: bottom;
  white-space: nowrap;
  padding-top: 18px;

`;

export default HeaderRightBlock;
