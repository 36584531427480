import styled, { css } from "styled-components";

const Table = styled.table `
  margin-top: 15px;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #f3f3f3;
`;

const Thead = styled.thead `
  background-color: #f9f9f9;
  height: 25px;
`;

const TBody = styled.tbody `
  height: 25px;
`;

const TFoot = styled.tfoot `
  height: 25px;
`;

const Tr = styled.tr `
  background-color: #ffffff;
  height: 25px; 
`;

const TrShipping = styled.tr `
  background-color: #f9f9f9;
  height: 25px;
`;

const TrTotalCost = styled.tr `
  background-color: #f3f3f3;
  height: 25px;
`;

const TrTotalCostIncVat = styled.tr `
  background-color: #f9f9f9;
  height: 25px;
`;

const Th = styled.th `
  border: 0;
  padding: 10px 20px;
  font-family: 'Intro Cond', Arial, "Helvetica Neue", sans-serif;
  font-size: 16px;
  text-align: left;
  line-height: normal;
  font-weight: 700;

  ${props => props.rightAlign && css`
    text-align: right;
  `};
  
  ${props => props.centerAlign && css`
    text-align: center;
  `};

  ${props => props.compress && css`
    width: 1px;
  `};

  ${props => props.noWrap && css`
    white-space: nowrap;
  `}
`;

const Td = styled.td `
  border: 0;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  padding: 10px 20px;
  font-family: Arial, "Helvetica Neue", sans-serif;
  line-height: normal;
  color: #222222;

  ${props => props.rightAlign && css`
    text-align: right;
  `};

  ${props => props.centerAlign && css`
    text-align: center;
  `};

  ${props => props.compress && css`
    width: 1px;
  `};

  ${props => props.noWrap && css`
    white-space: nowrap;
  `}

  ${props => props.bold && css`
    font-weight: bold;
  `}

  ${props => props.color && css`
  color: ${props.textColor};
`}
`;

const TdComments = styled.td.attrs({
  colSpan: 5
}) `
  padding: 10px 20px;
  font-style: italic;
`;

const ThCheckbox = styled(Th) `
  width: 40px;
  padding-left: 0;
  padding-right: 0;
`;


const ThQuantity = styled(Th) `
  width: 80px;
  padding-left: 0;
  padding-right: 0;
`;



export { 
  Table,
  TBody, 
  TFoot, 
  Thead, 
  Tr,
  TrShipping, 
  TrTotalCost,
  TrTotalCostIncVat, 
  Th, 
  Td, 
  ThCheckbox, 
  ThQuantity, 
  TdComments 
};
