import styled from "styled-components";
import { NavLink } from "react-router-dom";

const HeaderNavLink = styled(NavLink)
`
  display: inline-block;
  color: #005aa0;
  margin-left: 15px;
  font-family: "Intro Cond", Arial, "Helvetica Neue", sans-serif;

  &:hover {
      color: #002f54;
  }

  &.active {
    color: #02234a;
  }

`;

const DownloadLink = styled.a`
  display: inline-block;
  color: #005aa0;
  margin-left: 15px;
  font-family: "Intro Cond", Arial, "Helvetica Neue", sans-serif;

  &:hover {
      color: #002f54;
  }

  &.active {
    color: #02234a;
  }
`

export default HeaderNavLink;

export { DownloadLink, HeaderNavLink };
