import { NavigationLink } from "../styled/Links";
import styled from "styled-components";

const TogglePasswordLink = styled(NavigationLink) `
  font-size: 0.95em;
  float: right;
  margin-right: 0;
  user-select: none;
`;

export default TogglePasswordLink;
