import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ModalOverlay from "./ModalOverlay";
import ModalContent from "./ModalContent";
import ModalHeader from "./ModalHeader";
import ModalHeaderTitle from "./ModalHeaderTitle";
import ModalClose from "./ModalClose";
import ModalBody from "./ModalBody";
import ModalParagraph from "./ModalParagraph";
import ModalFooter from "./ModalFooter";
import { NavigationButton, PrimaryButton } from "../styled/Buttons";

import t from "../../translation";

export default class Modal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isEnabled: true
    };
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  isExecuting = false;
  execute(trunk) {
    if (this.isExecuting) {
      return;
    }

    this.isExecuting = true;
    this.setState({ isEnabled: false }, () => {
      setTimeout(
        () => { 
          this.setState({ isEnabled: true }, () => {
            this.isExecuting = false;
          });          
        },
        1000);

      trunk();
    });    
  }

  render () {
    const modalParagraphs = this.props.texts.map((text, index) => {
      return <ModalParagraph key={index}>{ text }</ModalParagraph>
    });

    return this.props.visible ? (
      <ModalOverlay onClick={this.stopPropagation}>
        <ModalContent>
          <ModalHeader>
            <ModalHeaderTitle>{ this.props.header }</ModalHeaderTitle>
            <ModalClose id={ "modal-close-button" } onClick={ this.props.onAbort }></ModalClose>
          </ModalHeader>
          <ModalBody>
            { modalParagraphs }
          </ModalBody>
          <ModalFooter>
            <NavigationButton id={ "modal-abort-button" } disabled={ !this.state.isEnabled } onClick={ () => this.execute(this.props.onAbort) }>{ t(`MODAL_BUTTON_ABORT`) }</NavigationButton>
            <PrimaryButton id={ "modal-confirm-button" } disabled={ !this.state.isEnabled } onClick={ () => this.execute(this.props.onConfirm) }>{ t(`MODAL_BUTTON_CONFIRM`) }</PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    ) : null;
  }
}

Modal.propTypes = {
  visible: PropTypes.bool,
  header: PropTypes.string,
  texts: PropTypes.array,
  onConfirm: PropTypes.func,
  onAbort: PropTypes.func,
}
