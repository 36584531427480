import React from 'react'
import PropTypes from 'prop-types'

import { SectionWrapper, SectionHeader, Small } from "../styled";

import t from "../../translation";

class UserDetails extends React.Component {

  render () {
    const { email, username } = this.props;

    return (
      <SectionWrapper>
        <SectionHeader>{ t("PROFILE_MY_DETAILS") }</SectionHeader>
        <p id="profile-my-profile-username">{ username }</p>
        <p id="profile-my-profile-username">{ email }</p>
        <Small>{ t("PROFILE_MY_DETAILS_PARAGRAPH") }</Small>
      </SectionWrapper>
    )
  }
}

UserDetails.propTypes = {
  username: PropTypes.string,
  email: PropTypes.string,
}

export default UserDetails;
