import styled from "styled-components";

const ModalOverlay = styled.div `
  background-color: rgba(0,0,0,0.3);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  overflow-x: hidden;
  overflow-y: auto;
`;

export default ModalOverlay;
