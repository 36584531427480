
import React from "react";
import styled from "styled-components";
import t from "../../translation";

import shipped from '../../assets/checkbox_check.svg'

const Icon = styled.div `
border: 0; 
width: 1em;
height: 1em;
display: inline-block;
vertical-align: top;
mask-size: contain;
mask-position: center;
mask-repeat: no-repeat;
`

const ShippedIcon = styled(Icon).attrs({
    title: t("ORDER_DETAILS_SHIPPED")
}) `
    background-color: green;
    mask-image: url(${shipped});  
`;

export default () => {
    return (<ShippedIcon />);
};