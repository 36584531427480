import styled from "styled-components";

const ModalContent = styled.div `
  background-color: #ffffff;
  max-width: 500px;
  margin: 30px auto;
  box-shadow: 0 0 10px 2px rgba(0,0,0,.06), 0 0 1px 1px rgba(0,0,0,.05);

  @media (max-width: 500px) {
   margin: 15px;
  }
`;

export default ModalContent;
