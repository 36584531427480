import styled, { css } from "styled-components";

const CreateUserInputWrapper = styled.div `
  margin-right: 0px;
  width: 100%;
  padding: 5px
}
  
  ${props => props.width && css`
    width: ${props.width};
    `}
`;

export default CreateUserInputWrapper;
