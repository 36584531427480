import styled from "styled-components";

const HeaderWrapper = styled.div `
  background-color: #ffffff;
  border-bottom: 3px solid #B1B3B6;
  margin-bottom: 10px;
  padding: 30px;
`;

export default HeaderWrapper;
