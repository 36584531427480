import React from 'react'
import PropTypes from 'prop-types'

import { SectionWrapper, SectionHeader, Input, Button, ErrorMessage } from "../styled";

import CreateUserForm from "./CreateUserForm.jsx";
import CreateUserInputWrapper from "./CreateUserInputWrapper.jsx";
import CreateUserButton from "./CreateUserButton.jsx";

import t from "../../translation";
import { validation } from "../../utils";

class NewUserForm extends React.Component {

  focusName() {
    this.nameInput.focus();
  }

  render () {
    const creatingNewUser = this.props.creatingNewUser;
    const suppressErrorMessages = this.props.suppressErrorMessages;
    const formErrorMessage = this.props.formErrorMessage;

    return (
      <SectionWrapper>
        <SectionHeader>{ t("NEW_USER_HEADER") }</SectionHeader>
        <p>{ t("NEW_USER_PARAGRAPH") }</p>
        <CreateUserForm onSubmit={ this.props.onSubmit } disabled={ creatingNewUser } noValidate>
          <CreateUserInputWrapper>
            <Input
              id="add-user-name"
              ref={ (ref) => { this.nameInput = ref } }
              placeholder={ t("NEW_USER_NAME_PLACEHOLDER") }
              value={ this.props.name }
              onChange={ (e) => this.props.onNameChange(e.target.value) }
              type="text"
              disabled={ creatingNewUser }
              invalid={ !suppressErrorMessages && !validation.isString(this.props.name,3,100) }
            />
          <ErrorMessage visible={ !suppressErrorMessages && !validation.isString(this.props.name, 3, 100) }>{ t("VALIDATION_ERROR_NAME") }</ErrorMessage>
          </CreateUserInputWrapper>
          <CreateUserInputWrapper>
            <Input
              id="add-user-email"
              placeholder={ t("NEW_USER_EMAIL_PLACEHOLDER") }
              value={ this.props.email }
              onChange={ (e) => this.props.onEmailChange(e.target.value) }
              type="email"
              disabled={ creatingNewUser }
              invalid={ !suppressErrorMessages && !validation.isEmail(this.props.email) }
            />
          <ErrorMessage visible={!suppressErrorMessages && !validation.isEmail(this.props.email)}>{t("VALIDATION_ERROR_EMAIL")}</ErrorMessage>
          </CreateUserInputWrapper>
          <CreateUserButton id="add-user-submit" large secondary disabled={ creatingNewUser }>
            { creatingNewUser ? t("NEW_USER_CREATING_BUTTON") : t("NEW_USER_CREATE_BUTTON") }
          </CreateUserButton>
        </CreateUserForm>
        <ErrorMessage block visible={ !suppressErrorMessages && formErrorMessage != null }>{ this.props.formErrorMessage }</ErrorMessage>
      </SectionWrapper>
    )
  }

}

NewUserForm.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  onNameChange: PropTypes.func,
  onEmailChange: PropTypes.func,
  onSubmit: PropTypes.func,
  suppressErrorMessages: PropTypes.bool,
  formErrorMessage: PropTypes.string,
}

export default NewUserForm;
