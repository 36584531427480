
import React from "react";
import styled from "styled-components";
import t from "../../translation";

const Icon = styled.div `
position: relative;
display: inline-block;
width: 15px;
height: 15px;
overflow: hidden;
cursor: pointer;
&:after {
  transform: rotate(-45deg);
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #999;
  height: 4px;
  border-radius: 4px;
}
&:before {
  transform: rotate(45deg);
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #999;
  height: 4px;
  border-radius: 4px;
}
`

const RemoveIcon = styled(Icon).attrs({
    title: t("Ta bort")
}) `
`;

export default () => {
    return (<RemoveIcon />);
};