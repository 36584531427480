import React, { useState, useEffect } from "react";
import t from "../../translation";
import {updateTermsUrl, getTerms} from "../../services/api";
import { 
    Input,
    SectionWrapper,
    SectionHeader,
    SectionIntro,
    InputWrapper
 } from "../styled";
import CreateTermsButton from './CreateTermsButton';
import CreateSecretForm from '../CreateSecret/CreateSecretForm';
import "./style.css";





const Terms = (props) => {
    const defaultErrorMessage= "Du har inte fyllt i en korrekt URL";
    const [showValidationError, setShowValidationError] = useState(false);
    const [partnerTermsUrl,setPartnerTermsUrl] = useState("");
    const [errorMessage,setErrorMessage] = useState(defaultErrorMessage);
    const [busy,setBusy] = useState(false);
    
    useEffect(()=>{
        getTerms().then(res=>{
            let termsUrl = res.terms_url;
            if(termsUrl === null){
                setShowValidationError(true);
                setErrorMessage("Länk till villkor saknas!")
                return;
            }
            if(termsUrl.length > 0){
                setPartnerTermsUrl(termsUrl);
            }
        }).catch(err => {
            setShowValidationError(true);
            setErrorMessage("Länk till villkor saknas!")
        })


    },[])
    
    const submitTerms = (e)=>{
        setBusy(true);
        e.preventDefault();
        updateTermsUrl({terms_url:partnerTermsUrl}).then(r=>{
            setBusy(false);

        }
        ).catch(e=>{
            setBusy(false);
            console.log("Error saving terms");
        });
    }

    const handleChange = (e) =>{
        const value= e.target.value;
        setPartnerTermsUrl(value);
        if(!validateUrl(value)){
            setShowValidationError(true);
            setErrorMessage(defaultErrorMessage);
        }
        else{
            setShowValidationError(false);
        }

    }

    const validateUrl = (value) =>{
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(value);
    }
    return (
        <SectionWrapper>
                <SectionHeader>{ t("TERMS_HEADER")}</SectionHeader>
                <SectionIntro>{ t("TERMS_DESCRIPTION")}.</SectionIntro>
                <CreateSecretForm>

                <InputWrapper>
                    <Input placeholder="URL till era köpvillkor" onChange={handleChange} value={partnerTermsUrl} text={partnerTermsUrl} id={"partner_terms_url"}  label={t("TERMS_HEADER")}/>
                    {
                        showValidationError &&
                    <span className="errorMessage" >{errorMessage}</span>
                }
                </InputWrapper>
                <CreateTermsButton large secondary  disabled={showValidationError || busy} id="terms_button" onClick={(event) => submitTerms(event)}>Spara</CreateTermsButton>
                </CreateSecretForm>
        </SectionWrapper>
    )
}
export default Terms;
