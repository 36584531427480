import styled from "styled-components";

const ModalHeaderTitle = styled.h4 `
  color: #00427a;
  font-size: 1.5rem;
  line-height: 1.3;
  font-family: "Intro Cond Bold",Arial,"Helvetica Neue",sans-serif;
  font-weight: normal;
  margin: 0;
`;

export default ModalHeaderTitle;
