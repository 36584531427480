const dictionary = {
  WELCOME_MESSAGE: `Välkommen till Partner portalen!`,
  ORDER_SUMMARY: `Orderöversikt`,
  SETTINGS: `Inställningar`,
  USERS: `Användare`,
  SECURITY: `Säkerhet`,
  REPORT_PAYMENT: `Utbetalningsrapport`,
  REPORTS: `Rapporter`,
  FAQ: `FAQ`,
  NAME: `Namn`,
  EXPIRES: `Löper ut`,
  SECRET: `API-nyckel`,
  COPIED: `Kopierad`,
  CLOSE: `Stäng`,
  DELETE: `Ta bort`,

  ORDER_TABLE_HEADER_STATUS: `Status`,
  ORDER_TABLE_HEADER_DATE: `Datum`,
  ORDER_TABLE_HEADER_ORDER_NUMBER: `Ordernummer`,
  ORDER_TABLE_HEADER_ORDER_PRODUCT: `Finansieringsprodukt`,
  ORDER_TABLE_HEADER_DEBIT_INVOICE_NO: `Fakturanummer`,
  ORDER_TABLE_HEADER_CREDIT_INVOICE_NO: `Kreditfakturanummer`,
  ORDER_TABLE_HEADER_SHIPMENT_CREDITED: `Krediterad`,
  ORDER_TABLE_HEADER_CUSTOMER: `Kund`,
  ORDER_TABLE_HEADER_ORDER_TYPE: `Finansiering`,
  ORDER_TABLE_HEADER_AMOUNT: `Belopp`,
  ORDER_TABLE_NO_ORDERS_FOR_STATUS: `Ingen order med statusen`,
  ORDER_TABLE_NO_ORDERS_BASED_ON_FILTER_SETTINGS: `Välj status ovan för att se ordrar.`,
  ORDER_STATUS_PENDING: `Inväntar signering/beslut`,
  ORDER_STATUS_PENDING_SIGNING: `Inväntar signering`,
  ORDER_STATUS_PENDING_APPROVAL: `Inväntar beslut`,
  ORDER_STATUS_READY_TO_SHIP: `Redo att skicka`,
  ORDER_STATUS_SHIPPED: `Skickad`,
  ORDER_STATUS_COMPLETED: `Utbetald`,
  ORDER_STATUS_CANCELED: `Avbruten`,
  ORDER_TYPE_LEASING: `Leasing`,
  ORDER_TYPE_INVOICE: `Faktura`,
  ORDER_TYPE_RENTAL: `Hyra`,
  ORDER_TYPE_LEASING_OR_RENTAL:`Leasing/Hyra`,
  SEARCH_PLACEHOLDER: `Sök...`,
  LOGIN_FORM_ERROR_WRONG_USERNAME_OR_PASSWORD: `Användarnamnet eller lösenordet är felaktigt`,
  LOGIN_FORM_USERNAME_LABEL: `Användarnamn`,
  LOGIN_FORM_PASSWORD_LABEL: `Lösenord`,
  LOGIN_FORM_USERNAME_PLACEHOLDER: `Ange ditt användarnamn`,
  LOGIN_FORM_PASSWORD_PLACEHOLDER: `Ange ditt lösenord`,
  LOGIN_FORM_SUBMIT_LABEL: `Logga in`,
  LOGGED_OUT_HEADER: 'Välkommen till Partnerportalen.',
  LOGGED_OUT_PARAGRAPH: 'Vänligen logga in genom att klicka på länken.',

  ORDER_DETAILS_ORDER_MISSING: `Order saknas`,
  ORDER_DETAILS_ORDER_LOADING: `Laddar...`,

  ORDER_DETAILS_ORDER_NUMBER: `Ordernummer`,
  ORDER_DETAILS_PARTNER_DOMAIN: `Domän`,
  ORDER_DETAILS_COMPANY_NAME: `Företagsnamn`,
  ORDER_DETAILS_ORGANIZATION_NUMBER: `Organisationsnummer`,
  ORDER_DETAILS_SHIPPING_ADDRESS: `Leveransadress`,
  ORDER_DETAILS_BILLING_ADDRESS: `Faktureringsadress`,
  ORDER_DETAILS_CONTRACT_NUMBER: `Avtalsnummer`,

  ORDER_DETAILS_SHIP_ITEMS_HEADER: `Bekräfta leverans`,
  ORDER_DETAILS_SHIP_ITEMS: `Bekräfta leverans...`,
  ORDER_DETAILS_CONFIRM_SHIPMENT: `Bekräfta leverans av artiklar`,

  ORDER_DETAILS_CANCEL_ITEMS_HEADER: `Makulera`,
  ORDER_DETAILS_CANCEL_ITEMS: `Makulera...`,  
  ORDER_DETAILS_CONFIRM_CANCELLATION: `Makulera valda artiklar`,

  ORDER_DETAILS_CHANGE_ITEMS_HEADER: `Ändra`,
  ORDER_DETAILS_ADDED_ITEMS_HEADER: `Tillagda`,
  ORDER_DETAILS_CHANGE_ITEMS: `Ändra...`,  
  ORDER_DETAILS_CONFIRM_CHANGE: `Bekräfta ändring av order`,
  ORDER_DETAILS_CONFIRM_CANCEL_REMAINING: `Makulera alla artiklar`,
    
  NEW_ITEM_CREATE_BUTTON: `Lägg till`,
  NEW_ITEM_CREATING_BUTTON: `Lägger till`,
  NEW_ITEM_PRICE_PLACEHOLDER: `Pris`,
  NEW_ITEM_NUMBER_OF_ITEMS_PLACEHOLDER: `Antal`,
  NEW_ITEM_ARTICLE_NUMBER_PLACEHOLDER: `Artnr`,
  NEW_ITEM_NAME_PLACEHOLDER: `Produkt`,
  NEW_ITEM_PARAGRAPH: `Lägger till ny produkt till ordern`,
  ADD_ITEM_HEADER: `Lägg till ny produkt`,
  VALIDATION_ERROR_PRODUCT_NAME: `Vänligen ange ett giltigt produktnamn (1 till 100 tecken)`,
  VALIDATION_ERROR_ARTICLE_NUMBER: `Vänligen ange ett giltigt artikelnummer (1 till 100 tecken)`,
  VALIDATION_ERROR_NUMBER_OF_ITEMS:`Vänligen ange ett giltigt antal artiklar (1 till 999)`,
  VALIDATION_ERROR_PRICE:`Vänligen ange ett giltigt pris (1 till 999999)`,

  ORDER_DETAILS_RECIPIENT_NAME: `Mottagare`,
  ORDER_DETAILS_RECIPIENT_HEADER: `Leveransmottagare`,
  ORDER_DETAILS_PURCHASER_HEADER: `Kontaktperson`,
  ORDER_DETAILS_PURCHASER_EMAIL: `Epost`,
  ORDER_DETAILS_RECIPIENT_PHONE: `Telefon`,

  ORDER_DETAILS_REMAINING_SIGNATORIES: 'Inväntar signering av',
  ORDER_DETAILS_REMAINING_SIGNATORIES_NAME: 'Namn',
  ORDER_DETAILS_REMAINING_SIGNATORIES_PNUMBER: 'PersonNr',
  ORDER_DETAILS_REMAINING_SIGNATORIES_REMINDER_HEADER: 'Skicka påminnelse',
  ORDER_DETAILS_REMAINING_SIGNATORIES_EMAIL: 'Epost',
  ORDER_DETAILS_REMAINING_SIGNATORIES_SEND_EMAIL: 'Skicka ut signeringsmail',
  ORDER_DETAILS_REMAINING_SIGNATORIES_SEND_BTN_SEND: 'Skicka',
  ORDER_DETAILS_REMAINING_SIGNATORIES_SEND_BTN_SENT: 'Skickat',
  ORDER_DETAILS_REMAINING_SIGNATORIES_SEND_EMAIL_ERROR: 'Oj, nu gick något fel. Var god försök igen!',

  ORDER_DETAILS_ORDER_SUMMARY_HEADER: `Ordersammanfattning`,
  ORDER_DETAILS_ORDER_SHIPMENTS_HEADER: `Leveranser`,
  ORDER_DETAILS_ORDER_CANCELLATIONS_HEADER: `Makulerade artiklar`,
  ORDER_DETAILS_PRODUCT_NAME: `Produkt`,
  ORDER_DETAILS_PRODUCT_ID: `Artnr.`,
  ORDER_DETAILS_QUANTITIY: `Antal`,
  ORDER_DETAILS_QUANTITY_SHIPPED: `Levererat`,
  ORDER_DETAILS_PRICE_PER_ITEM: `à pris`,
  ORDER_DETAILS_PRICE: `Pris`,
  ORDER_DETAILS_VAT: `Moms`,
  ORDER_DETAILS_SHIPPING_COST: `Frakt`,
  ORDER_DETAILS_PRICE_TOTAL: `Total exklusive moms`,
  OLD_ORDER_DETAILS_PRICE_TOTAL: `Total`,
  ORDER_DETAILS_PRICE_TOTAL_INC_VAT:  `Total inklusive moms`,
  ORDER_DETAILS_ORDER_SHIPMENTS_SUBTOTAL: `Delsumma`,
  ORDER_DETAILS_COMMENTS_FIELD: `Intern kommentar...`,
  ORDER_DETAILS_SHIPPED: `Levererad`,
  ORDER_DETAILS_PARTIALLY_SHIPPED: `Delvis levererad`,

  MODAL_BUTTON_ABORT: `Avbryt`,
  MODAL_BUTTON_CONFIRM: `Bekräfta`,

  MODAL_HEADER_CANCEL_ORDER: `Makulera artiklar`,
  MODAL_TEXT_CANCEL_ORDER: `Är du säker på att du vill makulera valda artiklar?`,  
  MODAL_HEADER_CONFIRM_ORDER: `Leverera artiklar`,
  MODAL_TEXT_CONFIRM_ORDER: `Är du säker på att du vill leverera valda artiklar?`,
  MODAL_HEADER_REMOVE_USER: `Ta bort användare?`,
  MODAL_TEXT_REMOVE_USER: `Vill du verkligen ta bort användaren?`,

  NEW_USER_HEADER: `Lägg till användare`,
  NEW_USER_PARAGRAPH: `Användaren kommer att ha full access till partnerportalen.`,
  NEW_USER_NAME_PLACEHOLDER: `Namn`,
  NEW_USER_EMAIL_PLACEHOLDER: `Epost`,
  NEW_USER_CREATE_BUTTON: `Lägg till användare`,
  NEW_USER_CREATING_BUTTON: `Lägger till användare...`,

  USERS_TABLE_LOADING: `Laddar användare...`,
  USERS_TABLE_NO_USERS_TO_SHOW: `Det finns tyvärr inga användare att visa`,
  USERS_TABLE_HEADER_NAME: `Namn`,
  USERS_TABLE_HEADER_EMAIL: `Epost`,
  USERS_TABLE_BUTTON_EDIT: `Redigera`,
  USERS_TABLE_BUTTON_SAVE: `Spara`,
  USERS_TABLE_BUTTON_SAVING: `Sparar...`,
  USERS_TABLE_BUTTON_ABORT: `Avbryt`,
  USERS_TABLE_BUTTON_REMOVE: `Ta bort`,
  USERS_TABLE_EMAIL_INFORMATION: `För att ändra epostadressen, vänligen skapa en ny användare med önskad epost.`,

  PROFILE_MY_DETAILS: `Mina uppgifter`,
  PROFILE_MY_DETAILS_PARAGRAPH: `Dina uppgifter ändras av administratörer på sidan för användarhantering`,
  PROFILE_PASSWORD_POLICY: `Lösenord måste vara minst 6 tecken långt.`,
  PROFILE_PASSWORD_SERVER_ERROR: `Något gick fel när vi skulle ändra ditt lösenord`,
  PROFILE_SHOW_PASSWORD: `Visa lösenord`,
  PROFILE_HIDE_PASSWORD: `Dölj lösenord`,
  PROFILE_CHANGE_PASSWORD_HEADER: `Byt lösenord`,
  PROFILE_CHANGE_PASSWORD_BUTTON: `Byt lösenord`,
  PROFILE_CHANGING_PASSWORD_BUTTON: `Byter lösenord...`,
  PROFILE_PASSWORD_CHANGE_SUCCESS: `Du har nu bytt ditt lösenord.`,
  PROFILE_PASSWORD_PLACEHOLDER: `Lösenord`,

  VALIDATION_ERROR_EMAIL: `Vänligen ange en giltig email`,
  VALIDATION_ERROR_NAME: `Vänligen ange ett giltigt namn`,
  VALIDATION_ERROR_PASSWORD: `Vänligen ange ett giltigt lösenord`,
  VALIDATION_ERROR_DATE: `Vänligen ange ett giltigt datum`,

  TERMS_HEADER: `E-handlarens villkor`,
  TERMS_DESCRIPTION: `Du som e-handlare måste ange en länk till era villkor. Den länken kommer att visas i samband med att kunden godkänner köpet`,

  TEST_SECRET_HEADER: `Kontrollera API nyckel`,
  TEST_SECRET_DESCRIPTION: `Testa din API nyckel för att se att den är korrekt.`,
  TEST_SECRET_SUCCESS: `Nyckel är okej.`,
  TEST_SECRET_ERROR: `Felaktig klient eller hemlighet!`,
  
  NEW_CLIENT_SECRET: `Skapa API-nyckel`,
  NEW_CLIENT_SECRET_DESCRIPTION: `Skapa en ny API-nyckel att användas vid integrationen mot WasaKredits "checkout"-tjänster.`,
  NEW_CLIENT_SECRET_INFORMATION_PART_1: `Vid skapande av en ny API-nyckel kommer den nya nyckeln bli giltig till `,
  NEW_CLIENT_SECRET_INFORMATION_PART_2: ` och alla tidigare nycklar få en giltighetstid på max 3 månader.`,
  NEW_CLIENT_SECRET_EXPIRATION: `Den skapade API-nyckeln är giltig till`,
  NEW_CLIENT_SECRET_EXPLAIN: `Kopiera API-nyckeln och flytta den till din integration. OBS Det går ej att få tag på API-nyckeln igen efter detta.`,
  NEW_SECRET_CREATE_BUTTON: `Skapa API-nyckel`,
  REMOVE_CLIENT_SECRET_VERIFY_HEADER: `Ta bort API-nyckel`,
  REMOVE_CLIENT_SECRET_VERIFY_INTRO: `Denna funktion är oåterkallelig, om någon integration använder denna API-nyckel kommer den sluta fungera.`,
  REMOVE_CLIENT_SECRET_VERIFY_NAME: `Ange namnet på API-nyckeln du vill ta bort`,
  CLIENT_SECRET_CLIENT_ID: `KlientId`,
  VALIDATION_ERROR_SECRET_NAME: `Vänligen ange ett giltigt namn (minst 5 tecken) som inte finns i listan nedan`,
};

export default dictionary;

