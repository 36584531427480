import styled, {
  css
} from "styled-components";
import theme from "../../theme";

const SectionHeader = styled.h2 `
  margin: 0 0 20px 0;
  font-family: 'Intro Cond', Arial, "Helvetica Neue", sans-serif;
  font-weight: 700;
  color: #00427a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export default SectionHeader;
