import React, {Component} from "react";
import {HeaderNavLink, HeaderWrapper} from "../styled/";
import UserComponent from './User';
import wkLogo from "../../assets/wk.png";
import t from "../../translation";
import { getIsTestEnvironment } from "../../services/api";
import "./style.scss"

export default class Header extends Component {
    constructor() {
        super();
        this.state = {
            isTest: false,
        };
    }

    componentDidMount() {
        getIsTestEnvironment().then(res => {
            this.setState({ isTest: res.is_test });
        })
    }

    render() {
        return (
            <HeaderWrapper>
                <a id={"navigation-link-home"} href="/">
                    <img src={wkLogo} alt="Wasa Kredit logo"/>
                </a>
                { this.state.isTest && <span className="test-env-header">Test</span> }
                <HeaderNavLink id={"navigation-link-order-summary"} to="/" activeClassName={"active"}>
                    {t(`ORDER_SUMMARY`)}
                </HeaderNavLink>
                <HeaderNavLink id={"navigation-link-users"} to="/users" activeClassName={"active"}>
                    {t(`USERS`)}
                </HeaderNavLink>
                <HeaderNavLink id={"navigation-link-security"} to="/security" activeClassName={"active"}>
                    {t(`SECURITY`)}
                </HeaderNavLink>
                <HeaderNavLink id={"navigation-link-reports"} to="/reports" activeClassName={"active"}>
                    {t(`REPORTS`)}
                </HeaderNavLink>
                <HeaderNavLink id={"navigation-link-manual"} to="/faq" activeClassName={"active"}>
                    {t(`FAQ`)}
                </HeaderNavLink>
                <UserComponent/>
                <div style={{clear: 'both'}}/>
            </HeaderWrapper>
        );
    }
}
