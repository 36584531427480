import React, { Component } from "react";


export default class NotFound extends Component {
  render() {
    return (
      <div className="App-header" style={{ textAlign: "center" }}>
      <h2>{"404 - Sidan kunde tyvärr inte hittas"}</h2>
      </div>
    );
  }
}
