import React from 'react'
import "./index.css";

class CalloutBadge extends React.Component {

    render() {
        const header = this.props.header;
        const paragraph = this.props.paragraph;
        const buttontext = this.props.buttontext

        const renderTitle = h =>
            h ? <h3 className="callout-title">{h}</h3> : "";

        const renderParagraph = p =>
            p ? <p className="callout-text">{p}</p> : "";

        const renderButton = buttonText =>
            buttonText ?
                <button
                    className='callout-btn'
                    onClick={(event) => this.props.onClick(event)}>
                    {buttontext}</button> : "";

        return (
            <div className='callout my-2'>
                <div className="callout-figure">
                    <svg data-draw="" width="27px" height="27px" viewBox="0 0 27 27" version="1.1">
                        <polyline id="Path-2656" fill="none" strokeWidth="4" stroke="#E30613" points="3 27 3 3 27 3"></polyline>
                    </svg>
                </div>
                {renderTitle(header)}
                <div>
                    {renderParagraph(paragraph)}
                    {renderButton(buttontext)}
                    <div className="checkout-test-mode-indicator__clear"></div>
                </div>
            </div>
        )
    }

}

export default CalloutBadge;
