import React from "react";
import styled from "styled-components";
import t from "../../translation";

import partiallyShipped from '../../assets/half-filled-circle.svg'
import shipped from '../../assets/checkbox_check.svg'

const Icon = styled.div `
    border: 0; 
    width: 1em;
    height: 1em;
    display: inline-block;
    vertical-align: top;
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
`

const ShippedIcon = styled(Icon).attrs({
    title: t("ORDER_DETAILS_SHIPPED")
}) `
    background-color: green;
    mask-image: url(${shipped});  
`;

const PartiallyShippedIcon = styled(Icon).attrs({
    title: t("ORDER_DETAILS_PARTIALLY_SHIPPED")
}) `
    background-color: orange;
    mask-image: url(${partiallyShipped});
`;

export default (props) => {
    const { shippedQuantity, quantity } = props.item;

    if (shippedQuantity >= quantity) {
    return (<ShippedIcon />);
    }

    if (shippedQuantity > 0) {
    return (<div><PartiallyShippedIcon /> { ` ${shippedQuantity} / ${quantity}` }</div>)
    } 

    return "-";
};