import React, { Component } from 'react';
import t from '../../translation';
import { format } from "../../utils";
import {
    Tr,
    Td,
} from '../styled';
import { PrimaryButton } from '../styled/Buttons';
import { deleteSecret } from '../../services/api';


import RemoveSecretControl from '../RemoveSecretForm';

export default class SecretRowItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            verify: false,
        }

        this.onVerify = this.onVerify.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    onVerify() {
        this.setState({
            verify: true,
        });
    }

    onRemove(command) {
        deleteSecret(command)
            .then(res => {
                this.setState({
                    verify: false,
                });
                if(this.props.refresh){
                    this.props.refresh();
                }
            });
    }

    onCancel() {
        this.setState({
            verify: false,
        });
    }

    render() {
        return (
            <Tr>
                <Td>{ format.dateWithTime(this.props.data.expires_at) }</Td>
                <Td>{this.props.data.description}</Td>
                <Td>
                    <RemoveSecretControl onRemove={this.onRemove.bind(this)} onCancel={this.onCancel.bind(this)} secret={this.props.data} visible={this.state.verify}/>
                    <PrimaryButton onClick={this.onVerify} disabled={this.state.verify}>{ t("DELETE")}</PrimaryButton>
                </Td>
            </Tr>
        );
    }
}