import React from 'react';
import "./sortButton.scss";
export const SortButton2 = (props) => {
    return (
        <button onClick={props.onClick} className={`sort-button ${props.direction} ${props.active?'active':'' }`} />
    )
};



