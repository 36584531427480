import React, { useState, useEffect } from "react";
import { getDisclaimer } from "../../services/api";


import "./style.scss";


export const DisclaimerInfo = (props) => {
    const [disclaimers, setDisclaimers] = useState([]);
    useEffect(() => {
        getDisclaimer().then(body => {
            if (body.disclaimers.length > 0) {
                setDisclaimers(body.disclaimers);
            }
        })
    }, [])
    return (

        <div>
            {
                disclaimers.map((d,i) => {
                    return (
                        <div key={"disclaimer-item"+i} className="disclaimer-wrapper">
                            <span className="header">
                            {d.header}
                            </span>
                            <span>
                                {d.text}
                            </span>
                            
                            {
                                (d.url != null && d.url.length > 0) &&
                                <a className="disclaimer-link" href={d.url}>{d.link_label !== null ? d.link_label : d.url}</a>
                            }
                        </div>
                    )

                })
            }
        </div>
    )
}


