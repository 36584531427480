import React, { Component } from "react";
import styled, { css } from "styled-components";
import t from "../../../translation";
import { format } from "../../../utils";

import OrderDetails from ".."
import Counter from "../Counter"
import Checkbox from "../Checkbox"

import { PrimaryButton } from "../../styled/Buttons";
import { Table, Thead, TBody, TFoot, TrTotalCost, TrTotalCostIncVat, ThCheckbox, ThQuantity, Td, Th, Tr } from "../OrderTable";
import { Summary } from "../OrderTop";
import { ModalWindow, ModalOverlay } from "../OrderModal";

export default class ShippingModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            order: props.order,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.order) {
            return {
                order: null
            };
        }

        var shipped = props.order.shipments
            .reduce((p, c) => [ ...p, ...c.cart_items ], [])
            .reduce((p, c) => ({ ...p, [c.id]: c.quantity + (p[c.id] || 0) }), {});

        var cancelled = props.order.cancellations
            .reduce((p, c) => [ ...p, ...c.cart_items ], [])
            .reduce((p, c) => ({ ...p, [c.id]: c.quantity + (p[c.id] || 0) }), {});

        var remaining = props.order.cart_items
            .map(m => ({ ...m, quantity: m.quantity - (shipped[m.id] || 0) - (cancelled[m.id] || 0) }))
            .filter(f => f.quantity > 0);

        var resetState = props.order !== state.order   
            ? { selected: remaining.reduce((p, c) => ({ ...p, [c.id]: c.quantity }), {}), comments: "" }
            : { selected: state.selected, comments: state.comments };

        return {
            order: props.order,
            remainingItems:  remaining,
            selectedItems: remaining
                .filter(f => resetState.selected[f.id])
                .map(m => ({ ...m, quantity: resetState.selected[m.id] })),
            ...resetState                 
        };
    }
    
    render() {
        const order = this.state.order;
        if (order == null) {
            return null;
        }

        return (
            <ModalOverlay onClick={ (ev) => { ev.stopPropagation(); this.props.onClose(); } }>
                <ModalWindow onClick={ (ev) => { ev.stopPropagation(); } }>

                    <Summary>{ t(`ORDER_DETAILS_SHIP_ITEMS_HEADER`) }</Summary>
                    <Table>
                        <Thead>
                            <tr>                            
                                <ThCheckbox />
                                <Th>{ t(`ORDER_DETAILS_PRODUCT_NAME`) }</Th>
                                <Th compress>{ t(`ORDER_DETAILS_PRODUCT_ID`) }</Th>
                                <ThQuantity compress centerAlign>{ t(`ORDER_DETAILS_QUANTITIY`) }</ThQuantity>
                                <Th compress noWrap rightAlign>{ t(`ORDER_DETAILS_PRICE`) }</Th>
                            </tr>
                        </Thead>
                        <TBody>
                            { this.state.remainingItems
                                .map((item, index) => 
                                    (<Tr key={ item.id + "_" + index }>
                                        <Td><Checkbox
                                            defaultValue={ this.state.selected[item.id] }
                                            onCheck={ (checked) => {
                                                this.setState(state => ({ ...state, selected: { ...state.selected, [item.id]: checked ? item.quantity : 0 } }));
                                            } } /></Td>
                                        <Td>{ item.product_name }</Td>
                                        <Td compress noWrap>{ item.product_id }</Td>
                                        <Td noWrap centerAlign>{ 
                                            this.state.selected[item.id] 
                                                ? (<Counter 
                                                    rangeFrom={ 1 }
                                                    rangeTo={ item.quantity }
                                                    setter={ (v) => this.setState(state => ({ ...state, selected: { ...state.selected, [item.id]: v } })) } /> ) 
                                                : item.quantity }</Td>
                                        <Td compress noWrap rightAlign>{ format.currency(item.price_ex_vat.amount, item.price_ex_vat.currency) }</Td>
                                    </Tr>) ) }
                        </TBody>         
                        <TFoot>
                            <TrTotalCost>
                                <Td />
                                <Td bold>{ t(`ORDER_DETAILS_PRICE_TOTAL`) }</Td>
                                <Td></Td>
                                <Td></Td>
                                <Td id="shipping-order-details-total-cost-ex-vat" rightAlign noWrap bold>{ format.currency(OrderDetails.totalPriceExclVat(this.state.selectedItems, order), order.total_price_ex_vat.currency) }</Td>
                            </TrTotalCost>
                            <Tr>
                                <Td/>
                                <Td>{ t(`ORDER_DETAILS_VAT`) }</Td>
                                <Td></Td>
                                <Td></Td>
                                <Td id="shipping-order-details-total-vat" rightAlign noWrap>{ format.currency(OrderDetails.totalVat(this.state.selectedItems, order), order.total_price_ex_vat.currency) }</Td>
                            </Tr>
                            <TrTotalCostIncVat>
                                <Td/>
                                <Td>{ t(`ORDER_DETAILS_PRICE_TOTAL_INC_VAT`) }</Td>
                                <Td></Td>
                                <Td></Td>
                                <Td id="shipping-order-details-total-cost-inc-vat" rightAlign noWrap>{ format.currency(OrderDetails.totalPriceInclVat(this.state.selectedItems, order), order.total_price_ex_vat.currency) }</Td>
                            </TrTotalCostIncVat>
                        </TFoot>            
                    </Table>         

                    <div style={{ margin: "20px" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <input type="text" style={{ padding: "1em", margin: "1em 0 1em 0" }} defaultValue={this.state.comments} onChange={ e => this.setState({ "comments": e.target.value }) } placeholder={ t(`ORDER_DETAILS_COMMENTS_FIELD`) } />
                        </div>

                        <PrimaryButton 
                            id={"order-details-confirm-modal-button"} 
                            onClick={ () => this.props.onShip(this.state.selectedItems, this.state.comments) } 
                            disabled={ !this.state.selectedItems.length }                      
                            >
                            { t(`ORDER_DETAILS_CONFIRM_SHIPMENT`) }
                        </PrimaryButton>
                    </div>

                </ModalWindow>
            </ModalOverlay>)
    }
}

