import React from 'react'

import t from "../../translation";

import {
  Table,
  TBody,
  Th,
  THeadTr,
  Tr,
  Td,
  ErrorMessage,
} from "../styled";

import NoUsersToShow from "./NoUsersToShow.jsx";
import LoadingUsers from "./LoadingUsers.jsx";

export default class UsersTable extends React.Component {

  render () {
    const loadingUsersError = this.props.loadingUsersError;

    return (
      this.props.isLoading ?
        <LoadingUsers>
          {t("USERS_TABLE_LOADING")}
        </LoadingUsers> :
        !loadingUsersError ?
          <Table>
            <thead>
              <THeadTr>
                <Th style={{ width: "300px" }}>{ t(`USERS_TABLE_HEADER_NAME`) }</Th>
                <Th style={{ width: "400px" }}>{ t(`USERS_TABLE_HEADER_EMAIL`) }</Th>
                <Th></Th>
              </THeadTr>
            </thead>
            <TBody>
              {
                this.props.children.length === 0 ?
                  <Tr noHover>
                    <Td colSpan="4">
                      <NoUsersToShow>{ t("USERS_TABLE_NO_USERS_TO_SHOW") }</NoUsersToShow>
                    </Td>
                </Tr> :
                this.props.children
              }
            </TBody>
          </Table> :
          <ErrorMessage block visible>{ loadingUsersError }</ErrorMessage>
    )
  }
}
