import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Seperator } from "../../styled";
import { AuthService } from '../../../services/authService';
import "./user.css"

export default class UserComponent extends Component {
    constructor() {
        super();
        this.auth = new AuthService();

        this.state = {
            profile: null,
        };
    }

    componentDidMount() {
        this.auth.profile()
            .then(x => this.setState({ profile: x })).catch(err => {
                console.log(err)
            });
    }

    login(e) {
        e.preventDefault();
        window.location.href = "/app/login";
    }

    logout(e) {
        e.preventDefault();
        window.location.href = "/app/logout";
    }

    render() {
        return (
            <div className="UserDiv">
            {this.state.profile ? (
                <div>
                    <span><NavLink id={"navigation-link-profile"} className="NavLink" to="/profile"> {this.state.profile.name}</NavLink></span>
                    <Seperator />
                    <a className="LogoutLink" href="#" onClick={(event) => this.logout(event)}>Logga ut</a>
                </div>
            ) : null}
                {this.state.profile ? null : (
                    <div>
                        <a href="#" onClick={(event) => this.login(event)}>Logga in</a>
                    </div>)}
            </div>
        );
    }
}