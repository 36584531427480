import styled from "styled-components";

import { PrimaryButton } from "../styled/Buttons";

const CreateUserButton = styled(PrimaryButton) `
  height: 50px;
  white-space: nowrap;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-shrink: 0;
  flex-grow: 0;
`;

export default CreateUserButton;
