import React, {useState, useEffect, useImperativeHandle, forwardRef} from 'react';

import { Tr, Td } from "../OrderDetails/OrderTable";
import TrashIcon from "./TrashIcon";
import Counter from "./Counter";
import { newAndShinyCurrencyFormat } from '../../utils/format';

const RemainingArticles = forwardRef((props, ref) => {
    const [itemsList, setItemsList] = useState([]);
    
    useEffect(()=>{
        setItemsList(props.remaining_cart_items.map(x => ({
                id:x.id,
                original_quantity: x.quantity,
                removed_quantity: 0,
                selected_quantity: x.quantity,
                vat_amount: x.vat_amount,
                price_ex_vat: x.price_ex_vat
                })
            ));
    },[props.remaining_cart_items])

    useImperativeHandle(ref, () => ({
        cancelAllItems() { 
            itemsList.forEach(element => {
                onCancelItem(element.id);
            });
        }
      }));

    const onSelectedItemChanged = (selectedValue, id) =>{
        let changedItem = itemsList.find(x => x.id === id);
        if(changedItem !== undefined && changedItem !== null)
        {
            changedItem.removed_quantity = Math.abs(selectedValue - changedItem.original_quantity);
            changedItem.selected_quantity = selectedValue;
            let newItemsList = [...itemsList];
            newItemsList[newItemsList.findIndex(x => x.id === id)] = changedItem;
            
            let remainingItemsVat = newItemsList.reduce((p, c) => p + c.vat_amount.amount * (c.original_quantity - c.removed_quantity), 0);
            let remainingItemsExVat = newItemsList.reduce((p, c) => p + c.price_ex_vat.amount * (c.original_quantity - c.removed_quantity), 0);
            let removedItems = newItemsList.map(x=> ({id:x.id, quantity: x.removed_quantity}));
            props.onRemainingItemsChangedCallback({vat: remainingItemsVat, price_ex_vat: remainingItemsExVat, removed_items: removedItems});
            setItemsList(newItemsList);
        }
    }

    const onCancelItem = (id) => {
        onSelectedItemChanged(0, id);
    }
 
    return (
        <React.Fragment> 
            { itemsList.length > 0 && 
                (props.remaining_cart_items
                    .map((item, index) => 
                        (<Tr key={ item.id + "_" + index } id={"remaining_" + item.id + "_" + index }>
                            <Td>{ item.product_name }</Td>
                            <Td compress noWrap>{ item.product_id }</Td>
                            <Td noWrap centerAlign>{ 
                                (<Counter 
                                    rangeFrom={ 0 }
                                    rangeTo={ item.quantity }
                                    value = { itemsList.find(x => x.id === item.id).selected_quantity }
                                    setter={ (v) => onSelectedItemChanged(v, item.id)} /> ) 
                            }</Td>
                            <Td compress noWrap rightAlign>{ newAndShinyCurrencyFormat(item.price_ex_vat.amount, item.price_ex_vat.currency,2) }</Td>
                            <Td compress id="invoice-order-item-total" noWrap rightAlign>{ newAndShinyCurrencyFormat(item.price_ex_vat.amount*itemsList.find(x => x.id === item.id).selected_quantity, item.price_ex_vat.currency, 2) }</Td>
                            <Td compress noWrap id="invoice-order-cancel-item-td" rightAlign data-item={item} onClick={ e => onCancelItem(item.id)}>{ <TrashIcon />}</Td>
                        </Tr>) ) )}
        </React.Fragment>
    )
})
export default RemainingArticles;




