Date.prototype.addMonths = function(month) {
    var date = new Date(this.valueOf());
    date.setMonth(date.getMonth() + month);
    return date;
}

Date.prototype.addYears = function(years) {
    var date = new Date(this.valueOf());
    date.setFullYear(date.getFullYear() + years);
    return date;
}

Date.prototype.toISODate = function() {
    return this.toISOString().split("T")[0];
}

