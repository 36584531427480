import styled, { css } from "styled-components";

import theme from "../../theme";
import orderStatus from "../../enums/orderStatus";
import { Td } from "../styled";

const StatusTd = styled(Td) `
  padding: 10px 3px;


  ${props => props.status === orderStatus.pending_signing && css`
      background-color: ${theme.pending_signing};`}
  ${props => props.status === orderStatus.pending_approval && css`
      background-color: ${theme.pending_approval};`}
  ${props => props.status === orderStatus.readyToShip && css`
      background-color: ${theme.readyToShip};`}
  ${props => props.status === orderStatus.completed && css`
      background-color: ${theme.completed};`}
  ${props => props.status === orderStatus.shipped && css`
      background-color: ${theme.shipped};`}
  ${props => props.status === orderStatus.canceled && css`
      background-color: ${theme.canceled};`}

  /*

  */
`;

export default StatusTd;
