import React, {Component} from "react";

import {Table, TBody, Td, Th, Tr, THeadTr} from "../styled";

import StatusTd from "./StatusTd.jsx";
import StatusTh from "./StatusTh.jsx";
import NoOrderSpan from "./NoOrderSpan.jsx";
import{SortButton2} from './SortButton2.jsx';
import "./style.scss";
import t from "../../translation";
import orderStatus from "../../enums/orderStatus";

import {format} from "../../utils";
import OrderStatus from "./OrderStatus";
import orderType from "../../enums/orderType";
export default class OrdersTable extends Component {

    onRowClick(id, type) {
        this.props.orderClicked(id, type);
    }

    renderOrderRows() {
        return (
            <TBody>
                {
                    this.props.orders.length === 0 ?
                        (
                            <Tr noHover>
                                <StatusTd status={this.props.visibleStatuses}/>
                                <Td id={"empty_row_" + this.props.visibleStatuses} centerAlign colSpan="5" noWrap>
                                    {`${t("ORDER_TABLE_NO_ORDERS_FOR_STATUS")} ${t(orderStatus._translationKeys[this.props.visibleStatuses])?.toLowerCase()}`}
                                </Td>
                            </Tr>
                        ) 
                        :
                    this.props.orders.map(order => {
                        return (
                            <Tr id={"order_row_" + order.id} key={order.id}
                                onClick={this.onRowClick.bind(this, order.id, order.type)}>
                                <StatusTd status={order.orderFilterStatus}/>
                                <Td compress noWrap className="icon-container-td">
                                    <OrderStatus order={order}/>
                                        {t(orderStatus._translationKeys[order.orderFilterStatus])}
                                    </Td>
                                    <Td compress noWrap>{format.date(order.create_date_utc)}</Td>
                                    <Td compress noWrap>{t(orderType._translationKeys[order.type])}</Td>
                                    <Td compress noWrap data-hj-whitelist>{order.order_number}</Td>
                                    <Td data-hj-suppress>{order.customer_name}</Td>
                                    <Td compress rightAlign noWrap>
                                        {format.currency(order.status !== orderStatus.canceled ? order.total_price_ex_vat.amount : 0, order.total_price_ex_vat.currency)}
                                    </Td>
                                </Tr>
                            );
                    })
                }
                    
            </TBody>
        );
    }

    render() {
        const isActiveSort = (field, direction) => this.props.activeSort[0] === field && this.props.activeSort[1] === direction;

        const renderSortButtons = (field) =>{
            return (
            <React.Fragment>{
                ['asc','desc'].map((direction,i)=>
                    <SortButton2 id={'sbtn'+i}  onClick={() => this.props.sort([field, direction])} 
                        direction={direction}
                        active={isActiveSort(field, direction)} 
                                 key={direction} />
                )
            }
            </React.Fragment>
            )
        }

        return this.props.visibleStatuses.length > 0
            ? (<Table id="orderlist-table">
                <thead>
                <THeadTr>
                    <StatusTh/>
                    <Th compress className="order-status-header">{t(`ORDER_TABLE_HEADER_STATUS`)}</Th>
                    <Th compress noWrap className="date-header">{t(`ORDER_TABLE_HEADER_DATE`)}
                        {renderSortButtons("create_date_utc")}
                    </Th>
                    <Th compress>{t(`ORDER_TABLE_HEADER_ORDER_PRODUCT`)}</Th>
                    <Th compress>{t(`ORDER_TABLE_HEADER_ORDER_NUMBER`)}</Th>
                    <Th noWrap className="customer-name-header">{t(`ORDER_TABLE_HEADER_CUSTOMER`)}
                        {renderSortButtons("customer_name")}
                    </Th>
                    <Th compress noWrap className="total-price-header">{t(`ORDER_TABLE_HEADER_AMOUNT`)}</Th>
                </THeadTr>
                </thead>
                {this.renderOrderRows()}
            </Table>)
            : (<NoOrderSpan>{t(`ORDER_TABLE_NO_ORDERS_BASED_ON_FILTER_SETTINGS`)}</NoOrderSpan>);
    }
}

