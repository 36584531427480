import React,{useEffect,useState} from 'react';
import styles from '../OrderDetails/orderDetails.module.css';
import buttonStyles from '../OrderDetails/closeButton.module.css';
import orderStatus from "../../enums/orderStatus";
import t from "../../translation";

const ModalWrapper = (props) => {
    let [paymentType, setPaymentType] = useState("");
    useEffect(()=>{
            let p = "";
            if(props.order !== null && props.order.type === "invoice") {
                p = "Faktura";
            }
            else if(props.order !== null && (props.order.type === "leasing" || props.order.type === "") ){
                p = "Leasing";
            }
            setPaymentType(p);
    },[props.order]);
    let hasOrder = props.order !== null;
    let className = hasOrder ? styles.in : styles.out;
    let status = hasOrder ? t(orderStatus._translationKeys[props.order.orderFilterStatus]) :
        "saknas";

    const orderIsShippedButInErrorState = (status, order) => {
        if (order == "loading") {
            return false;
        }
        //missing debitinvoices AND creditInvoices, indicates no answer from Arvato, order is set to Shipped anyway async, and we expect Arvato to finish.
        //But if it doesnt, we dont receive any debitInvoices. and then it is not really shipped, is it ?..
        if (status.toLowerCase() === "skickad" && (order.shipments[0].debited_shipment === null && order.shipments[0].credited_shipment === null)) {
            return true;
        }
        return false;
    }

    return (
        <div>
            <div className="modal-overlay" style={({display: props.order ? "block" : "none"})}/>
            <div className={styles.modal + ' ' + styles.fade + ' ' + className}>
                <div className={styles.modalDialog}>
                    <div className={styles.modalContent}>
                        <div className={styles.modalHeader}>
                            <div className={styles.status}>
                                {"Status: "}&nbsp;
                                {orderIsShippedButInErrorState(status, props.order)
                                    ?
                                    <strong id={"order-details-status"}>
                                        Faktura kunde ej skapas
                                    </strong>
                                    :
                                    <strong id={"order-details-status"}>
                                        {status}
                                    </strong>

                                }
                            {/*    <strong id={"order-details-status"}>*/}
                            {/*        {status}*/}
                            {/*    </strong>*/}
                            </div>
                            <div className={styles.status}>
                                {"Betalsätt: "}&nbsp;<strong id={"order-details-status"}>{paymentType}</strong>
                            </div>
                            <div id={"order-details-close"} onClick={props.onClose.bind(this)}
                                 className={buttonStyles.close + " " + styles.button}/>
                        </div>
                        {
                            props.children
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}
export default ModalWrapper;
