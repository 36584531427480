import React, { Component } from "react";

import ChangePasswordForm from "../ChangePasswordForm";
import UserDetails from "../UserDetails";
import { ContentWrapper } from "../styled";

import { updatePassword } from "../../services/api";
import { validation } from "../../utils";
import t from "../../translation";
import { AuthService } from '../../services/authService';

export default class Profile extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      userId: '',
      };

    this.authService = new AuthService();
    this.authService.profile()
        .then(x =>
            this.setState({
                userId: x.user_id,
                email: x.email,
                username: x.name
            })).catch(err => {
              console.log(err)
          });

    this.state = {
      username: "",
      email: "",
      newPassword: "",
      showPassword: false,
      errorMessage: null,
      isSaving: false,
      suppressErrorMessages: true,
      showPasswordChangeSuccess: false,
    };
  }

  onChangePassword(value) {
    this.setState({
      newPassword: value,
      showPasswordChangeSuccess: false,
    });
  }

  onShowPasswordToggle() {
    this.setState({
      showPassword: !this.state.showPassword
    });
  }

  onSubmitChangePassword(e) {
    e.preventDefault()

    if (!validation.isPassword(this.state.newPassword)) {
      this.setState({ suppressErrorMessages: false });
      return;
    }

    this.setState({
      errorMessage: null,
      isSaving: true,
      suppressErrorMessages: true,
      showPasswordChangeSuccess: false,
    });

    updatePassword({password: this.state.newPassword, id: this.state.userId})
      .then((res) => {
        this.setState({
          newPassword: "",
          isSaving: false,
          showPasswordChangeSuccess: true,
        });
      }).catch((err) => {
        this.setState({
          isSaving: false,
          errorMessage: t("PROFILE_PASSWORD_SERVER_ERROR"),
          suppressErrorMessages: false,
        });

      });
  }

  render() {

    return (
      <ContentWrapper>
        <UserDetails username={ this.state.username } email={ this.state.email } />
        <ChangePasswordForm
          password={ this.state.newPassword }
          onPasswordChange={ this.onChangePassword.bind(this) }
          onSubmit={ this.onSubmitChangePassword.bind(this) }
          saving={ this.state.isSaving }
          suppressErrorMessages={ this.state.suppressErrorMessages }
          formErrorMessage={ this.state.errorMessage }
          showPassword={ this.state.showPassword }
          onShowPasswordToggle={ this.onShowPasswordToggle.bind(this) }
          showPasswordChangeSuccess={ this.state.showPasswordChangeSuccess }
        />
      </ContentWrapper>

    );
  }
}
