import { PrimaryButton } from "../styled/Buttons";
import styled from "styled-components";

const CreateSecretButton = styled(PrimaryButton) `
  height: 50px;
  white-space: nowrap;
  margin-top: 30px;
  margin-bottom: 10px;
  flex-shrink: 0;
  flex-grow: 0;
`;

export default CreateSecretButton;
