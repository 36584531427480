import styled from "styled-components";

const InputLabel = styled.label `
  font-family: 'Intro Cond Bold', Arial, Helvetica;
  font-size: 0.95em;
  display: inline-block;
  color: #00427a;
`;

export default InputLabel;
