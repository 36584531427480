import styled from 'styled-components';

const ModalOverlay = styled.div `
    background-color: rgba(0,0,0,0.3);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    overflow-x: hidden;
    overflow-y: auto;
`;

const ModalWindow = styled.div `
    background-color: #ffffff;
    max-width: 800px;
    margin: 30px auto;
    padding: 20px;
    box-shadow: 0 0 10px 2px rgba(0,0,0,.06), 0 0 1px 1px rgba(0,0,0,.05);

    @media (max-width: 900px) {
        margin: 15px;
    }
`;

export { ModalOverlay, ModalWindow };