import styled from "styled-components";

const NoOrderSpan = styled.span `
  font-family: Arial, "Helvetica Neue", sans-serif;
  line-height: normal;
  font-size: 1rem;
  color: #222;
  display: flex;
  margin: 20px 0 0 0;
  justify-content: center;
`;

export default NoOrderSpan;
