export default {
    _translationKeys: {
        "leasing": ["ORDER_TYPE_LEASING"],
        "invoice": ["ORDER_TYPE_INVOICE"],
        "rental": ["ORDER_TYPE_RENTAL"],
        "":["ORDER_TYPE_LEASING_OR_RENTAL"]
    },
    leasing: "leasing",
    invoice: "invoice",
    rental: "rental"
}
