import React, { Component } from "react";
import { ContentWrapper } from "../styled";
import { getSecrets } from '../../services/api';
import CreateSecretControl from '../CreateSecret';
import SecretListControl from '../SecretList';
import { AuthService } from '../../services/authService';
import Terms from "../Terms";
import TestSecret from "../TestSecret";

export default class Security extends Component {

    constructor() {
        super();

        this.state = {
            clientName: '',
            secrets: [],
        };

        this.authService = new AuthService();
        this.authService.profile().catch(err => {
              console.log(err)
          });
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        getSecrets()
            .then(res => {
                this.setState({
                    secrets: res.secrets,
                    clientName: res.name,
                });
            })
            .catch(err => {
                console.log(err.message);
            })
    }

    render() {
        return (
        <ContentWrapper>
            <Terms/>
            <CreateSecretControl data={this.state.secrets} refresh={() => this.refresh()} />
            <SecretListControl data={this.state.secrets} refresh={() => this.refresh()} />
            <TestSecret/>
        </ContentWrapper>
        );
    }
}