import React, { useEffect, useState } from "react";
import { DownloadLink } from '../styled/HeaderNavLink'
import t from "../../translation";
import { getPaymentMethods,getInvoiceVatBasisList } from "../../services/api";
import moment from 'moment';
import "./style.scss";
import {
    ContentWrapper, SectionWrapper,
    SectionHeader
} from "../styled";

const Reports = (props) => {

    let date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    //moment.local().format('YYYY-MM-DD'); 
    const [paymentMethods, setPaymentMethods] = useState({ leasing: false, invoice: false });
    const [fromDate, setFromDate] = useState(moment(firstDay).format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment(lastDay).format('YYYY-MM-DD'));
    const [validationErrorMess, setValidationErrorMess] = useState("");
    const [invoiceVatBasisReportList,setInvoiceVatBasisReportList] = useState([]);
    useEffect(() => {
        getInvoiceVatBasisList().then(r=>{
            setInvoiceVatBasisReportList(r.vat_basis_report_datas);
        })

        getPaymentMethods().then((res) => {
            setPaymentMethods(res);
        }).catch(e => {
            console.log("error occured");
        });
    }, []);


    const onDateChange=(e, name)=>{
        setValidationErrorMess("");
        let selectedDate = e.target.value;
        if(name==="from"){
            if(selectedDate > toDate){
                setValidationErrorMess("Från-datum kan inte komma efter Till-datum");
                return;
            }
            setFromDate(selectedDate);
            
        }
        else{
            if(selectedDate < fromDate){
                setValidationErrorMess("Till-datum kan inte komma före Från-datum");
                return;
            }
            setToDate(selectedDate);

        }
    }

    return (
        <ContentWrapper>
            <SectionWrapper>
                <SectionHeader>Rapporter</SectionHeader>
                {
                    paymentMethods.leasing &&
                    <DownloadLink id={"download-paymentreport"} download href="/v1/reports/paymentreport" activeClassName={"active"}>
                        {t(`REPORT_PAYMENT`)} - Leasing
                        </DownloadLink>
                }
                {
                    paymentMethods.invoice &&
                    <React.Fragment>

                    <div className="invoice-reports-container">
                        <h4>{t(`REPORT_PAYMENT`)} - Faktura</h4>
                        <div className="col-container">
                            <div className="col">
                                <label>
                                    Från<br />
                                    <input placeholder="Från och med" id="from_date" name="from_date"
                                        type="date"
                                        onChange={(e) => onDateChange(e, "from")}
                                        value={fromDate} />
                                </label>
                            </div>
                            <div className="col">
                                <label>
                                    Till<br />
                                    <input placeholder="Till och med" id="to_date" name="to_date"
                                        
                                        type="date"
                                        onChange={(e) => onDateChange(e, "to")}
                                        value={toDate} />
                                </label>
                            </div>
                            <div className="col">
                            {
                                (validationErrorMess.length === 0 ) &&
                            <DownloadLink id={"download-paymentreport"} download href={`/v1/reports/paymentreport/invoice?from_date=${fromDate}&to_date=${toDate}`} activeClassName={"active"}>
                            Ladda ner rapport
                        </DownloadLink>
                            }

                            </div>
                        </div>
                        <div className="error-mess">
                            {validationErrorMess}
                        </div>
                    </div>
                    <div className="invoice-reports-container2">
                        <h4>Momsunderlag - Faktura</h4>
                        <div className="vatbasis-list">
                            {
                                invoiceVatBasisReportList.map((item, i)=>
                                    <li key={"li"+i}>
                                        <DownloadLink id={"download-paymentreport"} download href={`/v1/reports/paymentreport/invoice/vatbasislist/${item.id}`} activeClassName={"active"}>
                                        {item.display_date}
                                    </DownloadLink>
            
                                        
                                        </li>
                                )
                            }
                        </div>
                        
                        </div>
                    </React.Fragment>

                }
            </SectionWrapper>




        </ContentWrapper>
    );
}

export default Reports;