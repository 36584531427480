const validateEmail = (input) => {
  if (!input) {
    return false;
  }

  input = input.trim();

  if (
    !input.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    return false;
  }

  return true;
}

const validateString = (input, min, max) => {

  if (!input) {
    return false;
  }

  input = input.trim();

  if (input.length < (min||1) ) {
    return false;
  }

  if (input.length > (max|| 200)) {
    return false;
  }

  return true;
}

const validateNumber = (input, min, max) => {

  if (!input) {
    return false;
  }

  input = input.trim();

  var regex=/^[0-9]+$/;
  if (!input.match(regex))
  {
    return false;
  }
  let num = Number(input);
  if (num < min) {
    return false;
  }

  if (num > max) {
    return false;
  }

  return true;
}

const validateDecimal = (input, min, max) => {

  if (!input) {
    return false;
  }

  input = input.trim();
  
  if(isNaN(parseFloat(input)) || !isFinite(input)){
    return false;
  }

  let num = Number(input);

  if (num < min) {
    return false;
  }

  if (num > max) {
    return false;
  }

  return true;
}

const validatePassword = (input) => {

  if (!input) {
    return false
  }

  if (input.length < 6) {
    return false;
  }

  if (input.length > 256) {
    return false;
  }

  return true;
}

const validateDate = (input) =>{
  if (!input) {
    return false
  }

  if (input.length !== 10) {
    return false;
  }

  return true;
}

export default {
  isEmail:  validateEmail,
  isString: validateString,
  isNumber: validateNumber,
  isPassword: validatePassword,
  isDate: validateDate,
  isDecimal: validateDecimal,
}
