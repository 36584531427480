import React from "react";
import CalloutBadge from '../Callout'

import t from "../../translation";

export default class Logout extends React.Component {
    constructor(props) {
        super(props);
        this.handleLoginClick = this.handleLoginClick.bind(this);
      }

      handleLoginClick(e) {
        e.preventDefault();
        window.location.href = "/app/login";        
      }

      render() {
        return (<CalloutBadge 
            header={t(`LOGGED_OUT_HEADER`)} 
            paragraph={t('LOGGED_OUT_PARAGRAPH')}
            buttontext={t(`LOGIN_FORM_SUBMIT_LABEL`)}
            onClick={this.handleLoginClick}></CalloutBadge>
        )};
      
}