import styled from "styled-components";

const AddressTitle = styled.h3 `
  color: #222;
  font-family: "Intro Cond Bold", Arial, "Helvetica Neue", sans-serif;
  margin-bottom: 5px;
  display: block;
  margin-top: 0;
  font-size: 1.17em;
  padding: 0;
  line-height: 1.3;
  font-weight: normal;
`;


export default AddressTitle;
