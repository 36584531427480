import React, { Component } from "react";
import propTypes from "prop-types";

import orderStatus from "../../enums/orderStatus";
import t from "../../translation";

import StatusToggle from "./StatusToggle.js";
import SearchField from "./SearchField.jsx";
import FilterStatusWrapper from "./FilterStatusWrapper.jsx";
import FilterStatusHeader from "./FilterStatusHeader.jsx";
import { Input } from "../styled";

export default class OrdersFilter extends Component {

  statusVisible(status) {
    return this.props.visibleStatuses.indexOf(status) > -1;
  }

  render() {
    return (
      <FilterStatusWrapper>
        <StatusToggle id="filter-status-pending_signing" checked={ this.statusVisible(orderStatus.pending_signing) } toggle={ this.props.toggleStatus } counts={ this.props.counts }  status={ orderStatus.pending_signing } />
        <StatusToggle id="filter-status-pending_approval" checked={ this.statusVisible(orderStatus.pending_approval) } toggle={ this.props.toggleStatus } counts={ this.props.counts }  status={ orderStatus.pending_approval } />
        <StatusToggle id="filter-status-ready-to-ship" checked={ this.statusVisible(orderStatus.readyToShip) } toggle={ this.props.toggleStatus } counts={ this.props.counts } status={ orderStatus.readyToShip } />
        <StatusToggle id="filter-status-shipped" checked={ this.statusVisible(orderStatus.shipped) } toggle={ this.props.toggleStatus } counts={ this.props.counts } status={ orderStatus.shipped } />
        <StatusToggle id="filter-status-canceled" checked={ this.statusVisible(orderStatus.canceled) } toggle={ this.props.toggleStatus } counts={ this.props.counts } status={ orderStatus.canceled } />
        <SearchField id="filter-text" onChange={ e => this.props.changeText(e.target.value) } placeholder={ t("SEARCH_PLACEHOLDER") }  />
      </FilterStatusWrapper>
    );
  }
}