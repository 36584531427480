import React from 'react';
import t from '../../translation';


import { PrimaryButton, SecondaryButton } from '../styled/Buttons';
import { 
    SectionWrapper, 
    SectionHeader,
    SectionIntro,
    InputLabel,
    Input,
    InputWrapper,
    ModalPopup,
} from '../styled';

export default class RemoveSecretControl extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            verifyName: '',
            nameIsValid: false,
        };
        this.baseState = this.state 

        this.verifyName = this.verifyName.bind(this);
    }

    verifyName(val) {
        const isValid = this.isEqual(val);
        this.setState({
            verifyName: val,
            nameIsValid: isValid,
        });
    }

    getCommand() {
        return {
            client_id: this.props.secret.client_id,
            expire_date_time: this.props.secret.expires_at,
            description: this.props.secret.description,
        };
    }

    isEqual(str) {
        return (str === this.props.secret.description);
    }

    resetForm = () => {
        this.setState(this.baseState)
        this.props.onCancel()
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.secret.description !== this.props.secret.description) || 
            (prevProps.secret.expire_date_time !== this.props.secret.expire_date_time))
            {
                this.resetForm();
            }
      }

    render() {
        const nameInValid = !this.state.nameIsValid;
        return (
                this.props.visible ? (<ModalPopup>
                    <SectionWrapper>
                        <SectionHeader>{ t("REMOVE_CLIENT_SECRET_VERIFY_HEADER") }</SectionHeader>
                        <SectionIntro>{ t("REMOVE_CLIENT_SECRET_VERIFY_INTRO") }</SectionIntro>
                        <p style={{fontWeight: 'bold'}}>{this.props.secret.description}</p>
                        <InputWrapper>
                            <InputLabel>{ t("REMOVE_CLIENT_SECRET_VERIFY_NAME") }</InputLabel>
                            <Input id={"remove-secret-text-input"} invalid={nameInValid} value={this.state.verifyName} onChange={e => this.verifyName(e.target.value)}></Input>
                        </InputWrapper>
                        <PrimaryButton id={"remove-secret-confirm-modal"} onClick={e => this.props.onRemove(this.getCommand())} disabled={nameInValid}>{ t("DELETE") }</PrimaryButton>
                        <SecondaryButton id={"remove-secret-close-modal"} onClick={this.resetForm}>{ t("CLOSE") }</SecondaryButton>
                    </SectionWrapper>
                </ModalPopup>):null
        );
    }
}